import React, { useEffect } from "react";

import { Box, Paper, Typography, Grid } from "@material-ui/core";
import DownloadButton from "../../../util/DownloadButton";
import Axios from "axios";
import { Link } from "react-router-dom";
import { DateTime } from "../../../util/DateTime";
import { Skeleton } from "@material-ui/lab";
import VersionInfosServer from "../../../servers/VersionInfosServer";
import LicenceServer from "../../../servers/LicenceServer";
import { Localization } from "../../../localization/Localization";
import { release } from "os";
import { Version } from "../../../types";

interface Props {
  version: Version;
}

const LatestVersionDownload = ({ version }: Props) => {
  return (
    <>
      <Paper elevation={4}>
        <Box component={Paper} p={2}>
          <Typography variant="h4" color="primary" gutterBottom>
            {version ? (
              <Localization>downloads.latest_version</Localization>
            ) : (
              <Skeleton />
            )}
          </Typography>

          <Box mb={2}>
            <Grid container spacing={5}>
              <Grid item>
                <Typography>
                  {version ? (
                    <>
                      <Localization>downloads.version</Localization>: #
                      {version.number}
                    </>
                  ) : (
                    <Skeleton width={120} />
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {version ? (
                    <>
                      <Localization>downloads.revision</Localization>: #
                      {version.iteration}
                    </>
                  ) : (
                    <Skeleton width={60} />
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {version && (
                    <>
                      <Localization>downloads.release_date</Localization>
                      {": "}
                    </>
                  )}

                  {version ? (
                    <DateTime from={version.releaseDate} />
                  ) : (
                    <Skeleton width={120} />
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {version ? (
            <DownloadButton to={version.downloadUrl} />
          ) : (
            <Skeleton variant="rect" width={120} height={40} />
          )}
        </Box>
      </Paper>
    </>
  );
};

export default LatestVersionDownload;
