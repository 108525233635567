import React, { useEffect } from "react"
import { TextField, Grid, Button, Box } from "@material-ui/core"
import SaveAltIcon from "@material-ui/icons/SaveAlt"
import UserManagementServer from "../servers/UserManagementServer"
import { AppContext } from "../context/AppContext"
import { Localization } from "../localization/Localization"
import { PasswordInput } from "../util/PasswordInput"

class ChangePasswordForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: ""
    }
    this.baseState = this.state
  }

  handleSubmit = event => {
    const { appDispatch } = this.context

    if (this.state.newPassword !== this.state.newPasswordConfirmation) {
      appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: <Localization>profile.passwords_must_equal</Localization>
        }
      })
      return
    }

    UserManagementServer.post(`api/User/ChangePassword`, {
      currentPassword: this.state.currentPassword,
      newPassword: this.state.newPassword,
      newPasswordConfirmation: this.state.newPasswordConfirmation
    })
      .then(data => {
        this.setState(this.baseState)

        appDispatch({
          type: "showMessage",
          data: {
            severity: "success",
            value: <Localization>profile.password_changed</Localization>
          }
        })
      })
      .catch(error => {
        appDispatch({
          type: "showMessage",
          data: {
            severity: "error",
            value: `Error: ${error.response.data}`
          }
        })
      })
  }

  render() {
    return (
      <>
        <Grid container direction="column">
          <Grid item>
            <TextField
              variant="outlined"
              margin="dense"
              id="currentPassword"
              label={<Localization>profile.current_password</Localization>}
              type="password"
              autoComplete="off"
              fullWidth
              value={this.state.currentPassword}
              onChange={e => this.setState({ currentPassword: e.target.value })}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              margin="dense"
              id="newPassword"
              label={<Localization>profile.new_password</Localization>}
              type="password"
              fullWidth
              autoComplete="off"
              value={this.state.newPassword}
              onChange={e => this.setState({ newPassword: e.target.value })}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              margin="dense"
              id="newPasswordConfirmation"
              label={<Localization>profile.repeat_new_password</Localization>}
              type="password"
              fullWidth
              autoComplete="off"
              value={this.state.newPasswordConfirmation}
              onChange={e =>
                this.setState({ newPasswordConfirmation: e.target.value })
              }
            />
          </Grid>
          <Grid item>
            <Box mt={1} mb={1}></Box>
          </Grid>
          <Grid item>
            <Grid container justify="flex-end">
              <Grid item>
                <Button
                  onClick={this.handleSubmit}
                  size="small"
                  variant="contained"
                  color="secondary"
                  startIcon={<SaveAltIcon />}
                >
                  {<Localization>util.apply_button</Localization>}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }
}

ChangePasswordForm.contextType = AppContext

export default ChangePasswordForm
