import React from "react"

import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core"
import UserManagementServer from "../servers/UserManagementServer"
import VpnKeyIcon from "@material-ui/icons/VpnKey"

import copy from "clipboard-copy"
import { AppContext } from "../context/AppContext"
import { Localization } from "../localization/Localization"

class ResetPasswordOfUser extends React.Component {
  constructor(props) {
    super(props)
    this.user = this.props.user
    this.state = {
      token: null,
      open: false
    }
  }

  generateTokenAndOpenDialog = event => {
    UserManagementServer.get(`api/ManageUsers/${this.user}/ResetToken`).then(
      data => {
        this.setState({
          token: data,
          open: true
        })
      }
    )
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  copyToClipboard = () => {
    copy(this.getUrl())
    this.context.appDispatch({
      type: "showMessage",
      data: {
        severity: "info",
        value: "Reset password URL copied to clipboard."
      }
    })
  }

  sendViaEmail = () => {
    UserManagementServer.get(
      `api/ManageUsers/${this.user}/SendResetTokenToEmail`
    ).then(data => {
      this.setState({
        open: false
      })

      this.context.appDispatch({
        type: "showMessage",
        data: {
          severity: "info",
          value: "Reset password URL successfuly sent via e-mail."
        }
      })
    })
  }

  getUrl = () => {
    return `https://customer.microstep.sk/reset-password/${
      this.user
    }/${encodeURI(this.state.token)}`
  }

  render() {
    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          onClick={this.generateTokenAndOpenDialog}
          startIcon={<VpnKeyIcon />}
        >
          <Localization>users.reset_password</Localization>
        </Button>

        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogTitle>
            <Localization>users.generate_password_url</Localization>
          </DialogTitle>
          <DialogContent>
            <Typography style={{ wordWrap: "break-word" }}>
              <a rel="noopener noreferrer" target="_blank" href={this.getUrl()}>
                {this.getUrl()}
              </a>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.sendViaEmail}
              color="primary"
              variant="contained"
            >
              <Localization>users.send_via_email</Localization>
            </Button>
            <Button
              onClick={this.copyToClipboard}
              color="primary"
              variant="outlined"
            >
              <Localization>users.copy_to_clipboard</Localization>
            </Button>
            <Button
              onClick={this.handleClose}
              color="primary"
              variant="contained"
            >
              <Localization>util.close</Localization>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

ResetPasswordOfUser.contextType = AppContext

export default ResetPasswordOfUser
