import React, { useContext } from "react"
import { Localization } from "../localization/Localization"

export interface SoftwareVersionStringProps {
  versionNumber: number
  versionType: number
  versionIteration: number
}

export const SoftwareVersionString = ({
  versionNumber,
  versionType,
  versionIteration
}: SoftwareVersionStringProps) => {
  const getVersionTypeString = () => {
    switch (versionType) {
      case 1:
        return (
          <>
            <Localization>software.alpha</Localization>
          </>
        )
      case 2:
        return (
          <>
            <Localization>software.beta</Localization>
          </>
        )
      case 3:
        return (
          <>
            <Localization>software.stable</Localization>
          </>
        )
      default:
        break
    }
  }

  return (
    <>
      {getVersionTypeString()} #{versionNumber} Rev. {versionIteration}
    </>
  )
}
