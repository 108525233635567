import React, { useEffect } from "react";
import {
  TableHead,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Grid,
  Button,
  Box,
} from "@material-ui/core";

import Axios from "axios";

import Licence from "./components/Licence";
import { Skeleton } from "@material-ui/lab";
import LicenceServer from "../../servers/LicenceServer";
import { Localization } from "../../localization/Localization";

class Licences extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      licencesLoaded: false,
      licences: [],
    };
  }

  componentDidMount() {
    LicenceServer.get(`api/ManageLicences/of/${this.props.user}`).then(
      (data) => {

        data.sort((a, b) => {
          const nameComparison = a.product.localeCompare(b.product);
          if (nameComparison !== 0) {
            return nameComparison;
          }
        
          if (a.activated && !b.activated) {
            return -1;
          } else if (!a.activated && b.activated) {
            return 1;
          }
        });

        this.setState({ licencesLoaded: true, licences: data });
      }
    );
  }

  licenceDeleted = (id) => {
    this.setState((state, props) => {
      return {
        licences: state.licences.filter((licence) => licence.licenceId !== id),
      };
    });
  };

  render() {
    return (
      <>
        <TableContainer component={Paper} elevation={5}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Localization>licences.product</Localization>
                </TableCell>
                <TableCell>
                  <Localization>licences.licence_name</Localization>
                </TableCell>
                <TableCell>
                  <Localization>licences.computer_name</Localization>
                </TableCell>
                <TableCell>
                  <Localization>licences.activation_method</Localization>
                </TableCell>
                <TableCell>
                  <Localization>licences.status</Localization>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.licencesLoaded ? (
                this.state.licences.map((licence) => (
                  <Licence
                    key={licence.licenceId}
                    owner={this.props.user}
                    id={licence.licenceId}
                    product={licence.product}
                    displayName={licence.displayName}
                    onDelete={this.licenceDeleted}
                    licenceBranches={licence.productBranches}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

export default Licences;
