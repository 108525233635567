import React, { useEffect, useState } from "react"

import {
  Card,
  CardMedia,
  Box,
  CardContent,
  Typography
} from "@material-ui/core"
import logo from "../assets/microstep-logo.png"
import ResetPasswordForm from "./ResetPasswordForm"

const ResetPasswordCard = props => {
  return (
    <Card elevation={2}>
      <Box p={2}>
        <CardMedia component="img" image={logo} title="Logo" />
      </Box>
      <CardContent>
        <Typography color="primary" variant="body">
          Your username:{" "}
          <Typography variant="h6" display="inline">
            {" "}
            {props.match.params.username}
          </Typography>
        </Typography>
        <ResetPasswordForm match={props.match} />
      </CardContent>
    </Card>
  )
}

export default ResetPasswordCard
