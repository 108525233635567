import React, { useState, useEffect, useContext } from "react";

import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { UserForm } from "./UserForm";
import UserManagementServer from "../servers/UserManagementServer";
import { AppContext } from "../context/AppContext";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Localization } from "../localization/Localization";
import { User } from "../types";

interface EditUserDialogProps extends RouteComponentProps {
  userName: string;
  open: boolean;
  close: () => void;
}

const EditUserDialog = ({
  userName,
  open,
  close,
  history,
}: EditUserDialogProps) => {
  const [user, setUser] = useState<User>();
  const [currentMachineNumber, setCurrentMachineNumber] = useState<string>("");

  const [initialUser, setInitialUser] = useState<User>();
  const [initialUserLoaded, setInitialUserLoaded] = useState(false);

  const { appDispatch } = useContext(AppContext);

  useEffect(() => {
    UserManagementServer.get(`api/ManageUsers/${userName}`).then(
      (user: User) => {
        setInitialUser(user);
        setInitialUserLoaded(true);
      }
    );
  }, []);

  const handleSave = () => {
    if (currentMachineNumber != "") {
      appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: "Machine number must be assigned before creation.",
        },
      });
      return;
    }

    UserManagementServer.post(`api/ManageUsers/${userName}`, user)
      .then(() => {
        appDispatch({
          type: "showMessage",
          data: {
            severity: "success",
            value: "User edited successfuly.",
          },
        });
        close();
        history.push(`/users/${user?.userName}`);
        window.location.reload(false);
      })
      .catch((err) => {
        appDispatch({
          type: "showMessage",
          data: {
            severity: "warning",
            value: `Error: ${err.response.data}`,
          },
        });
      });
  };

  return (
    <>
      <Dialog open={open} onClose={close}>
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Localization>users.edit_user</Localization>
            </Grid>
            <Grid item>
              <IconButton onClick={close} color="primary">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          {initialUserLoaded && (
            <UserForm
              onCurrentMachineNumber={(currentMachineNumber) =>
                setCurrentMachineNumber(currentMachineNumber)
              }
              onChange={(user) => setUser(user)}
              initialUser={initialUser}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            startIcon={<SaveAltIcon />}
          >
            <Localization>util.save</Localization>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withRouter(EditUserDialog);
