import React from "react"
import { Grid } from "@material-ui/core"

import DashboardUpdates from "./DashboardUpdates"
import DashboardSubscription from "./DashboardSubscription"
import DashboardEvents from "./DashboardEvents"

function DashboardScreen() {
  return (
    <>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item md={7}>
          <DashboardUpdates />
        </Grid>
        <Grid item md={5}>
          <DashboardSubscription />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <DashboardEvents />
        </Grid>
      </Grid>
    </>
  )
}

export default DashboardScreen
