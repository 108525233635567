import { useImmerReducer } from "use-immer"
import React, { createContext, useReducer } from "react"
import Oidc from "../oidc/Oidc"
import { useEffect } from "react"
import { useContext } from "react"
import { AppContext } from "./AppContext"
import UserLoggedIn from "../oidc/UserLoggedIn"

import moment from "moment"
import { LocalizationContext } from "./LocalizationContext"
import UserManagementServer from "../servers/UserManagementServer"

const UserContextInitialState = {
  loggedIn: false,
  loggedUser: null,
  loginAttemptWithCredentials: null,
  loginAttemptWithToken: null,
  loginAttemptResults: [],
  logoutTimeout: null
}

function UserContextReducer(state, action) {
  switch (action.type) {
    case "loginWithCredentials": {
      state.loginAttemptWithCredentials = action.data
      break
    }
    case "loginWithToken": {
      state.loginAttemptWithToken = action.data
      break
    }
    case "logout": {
      console.log("loggin out")
      state.loggedIn = false
      state.loggedUser = null
      clearTimeout(state.logoutTimeout)
      state.logoutTimeout = null
      localStorage.removeItem("userToken")
      break
    }
    case "loginSuccessful": {
      state.loggedIn = true
      state.loggedUser = action.data
      state.loginAttemptWithCredentials = null
      state.loginAttemptWithToken = null
      state.loginAttemptResults.push(true)
      break
    }
    case "loginNotSuccessful": {
      state.loggedIn = false
      state.loggedUser = null
      state.loginAttemptWithCredentials = null
      state.loginAttemptWithToken = null
      state.loginAttemptResults.push(false)
      break
    }
    case "setLogoutTimeout": {
      state.logoutTimeout = action.data
    }
  }
}

const UserContext = createContext()

function UserContextComponent(props) {
  const { appDispatch } = useContext(AppContext)
  const { localizationDispatch } = useContext(LocalizationContext)

  const [userState, userDispatch] = useImmerReducer(
    UserContextReducer,
    UserContextInitialState
  )

  useEffect(() => {
    if (userState.loginAttemptWithCredentials == null) return

    const { username, password } = userState.loginAttemptWithCredentials
    Oidc.login(username, password)
      .then(user => {
        localStorage.setItem("userToken", user.token)
        Oidc.setupAxios(user.token)

        userDispatch({ type: "loginSuccessful", data: user })

        appDispatch({
          type: "showMessage",
          data: { severity: "success", value: "Login successful" }
        })

        localizationDispatch({
          type: "setCurrentLanguage",
          data: user.user.language
        })
      })
      .catch(err => {
        userDispatch({ type: "loginNotSuccessful" })

        appDispatch({
          type: "showMessage",
          data: { severity: "error", value: "Incorrect User name or Password!" }
        })
      })
  }, [userState.loginAttemptWithCredentials])

  useEffect(() => {
    if (userState.loginAttemptWithToken == null) return

    const { token } = userState.loginAttemptWithToken

    let user = new UserLoggedIn(token)

    localStorage.setItem("userToken", user.token)
    Oidc.setupAxios(user.token)

    userDispatch({ type: "loginSuccessful", data: user })

    appDispatch({
      type: "showMessage",
      data: { severity: "success", value: "Login successful" }
    })

    localizationDispatch({
      type: "setCurrentLanguage",
      data: user.user.language
    })
  }, [userState.loginAttemptWithToken])

  useEffect(() => {
    console.log("user context init")
    if (userState.loggedUser == null) {
      console.log("user context logged user is null")

      var existingUserToken = localStorage.getItem("userToken")
      if (existingUserToken == null) return

      var existingUser = new UserLoggedIn(existingUserToken)

      if (Math.floor(Date.now() / 1000) >= existingUser.validUntil) {
        localStorage.removeItem("userToken")
        return
      }

      localStorage.setItem("userToken", existingUser.token)
      Oidc.setupAxios(existingUser.token)

      userDispatch({ type: "loginSuccessful", data: existingUser })

      UserManagementServer.get(`api/User/Language`).then(lang => {
        localizationDispatch({
          type: "setCurrentLanguage",
          data: lang.name
        })
      })
    }
  }, [])

  useEffect(() => {
    if (userState.loggedUser) {
      if (moment().utc().unix() < userState.loggedUser.validUntil) {
        const timeout = userState.loggedUser.validUntil - moment().utc().unix()

        console.log("timeout: ")
        console.log(timeout)

        const id = setTimeout(() => {
          userDispatch({ type: "logout" })
        }, timeout * 1000)

        userDispatch({ type: "setLogoutTimeout", data: id })
      } else {
        userDispatch({ type: "logout" })
      }
    }
  }, [userState.loggedUser])

  return (
    <UserContext.Provider value={{ userState, userDispatch }}>
      {props.children}
    </UserContext.Provider>
  )
}

export { UserContext, UserContextComponent }
