import React, { useEffect } from "react";
import {
  TableHead,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Grid,
  Button,
  Box,
} from "@material-ui/core";

import Axios from "axios";

import SingleLicence from "./components/SingleLicence";
import { Skeleton } from "@material-ui/lab";
import LicenceServer from "../../servers/LicenceServer";
import { Localization } from "../../localization/Localization";
import { SoftwareContext } from "../../context/SoftwareContext";
import { Licence, Product } from "../../types";

interface Props {}

interface State {
  loaded: boolean;
  licences: Licence[];
}

class Licences extends React.Component<Props, State> {
  public static contextType = SoftwareContext;
  public product: Product;

  constructor(props: Props, context: any) {
    super(props, context);

    this.product = this.context.softwareState.product;

    this.state = {
      loaded: false,
      licences: [],
    } as State;
  }

  componentDidMount() {
    LicenceServer.get(`api/Licences/product/${this.product}`).then((data) => {
      this.setState({ loaded: true, licences: data } as State);
    });
  }

  render() {
    return (
      <>
        <TableContainer component={Paper} elevation={5}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Localization>licences.licence_name</Localization>
                </TableCell>
                <TableCell>
                  <Localization>licences.computer_name</Localization>
                </TableCell>
                <TableCell>
                  <Localization>licences.activation_method</Localization>
                </TableCell>
                <TableCell>
                  <Localization>licences.status</Localization>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.loaded ? (
                this.state.licences.map((licence: Licence) => (
                  <SingleLicence
                    key={licence.licenceId}
                    id={licence.licenceId}
                    displayName={licence.displayName}
                    product={this.product}
                    licenceBranches={licence.productBranches}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

export default Licences;
