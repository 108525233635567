import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import React, { useContext, useEffect, useState } from "react"
import { SoftwareContext } from "../../../context/SoftwareContext"
import { Localization } from "../../../localization/Localization"
import LicenceServer from "../../../servers/LicenceServer"
import PreviousVersionRow from "./PreviousVersionRow"
import { Version } from "../../../types";

interface State {
  versions: Version[]
}

const PreviousVersions = () => {
  const { softwareState } = useContext(SoftwareContext)
  const [state, setState] = useState<State>({
    versions: []
  } as State)

  useEffect(() => {
    fetchPreviousVersions()
  }, [softwareState])

  const fetchPreviousVersions = async () => {
    const { product, branch, type } = softwareState

    const versions: Version[] = await LicenceServer.get(
      `api/products/${product}/branch/${branch.number}/${branch.platform}/versions/${type}/history`
    )

    setState({ versions: versions } as State)
    console.log(versions)
  }

  return (
    <>
      <ExpansionPanel variant="outlined">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="older-versions-content"
          id="older-versions-content"
        >
          <Typography>
            <Localization>downloads.previous_versions</Localization>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Localization>downloads.version</Localization>
                  </TableCell>
                  <TableCell>
                    <Localization>downloads.revision</Localization>
                  </TableCell>
                  <TableCell>
                    <Localization>downloads.release_date</Localization>
                  </TableCell>
                  <TableCell>
                    <Localization>downloads.action</Localization>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {state.versions.map(version => (
                  <PreviousVersionRow version={version} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  )
}

export default PreviousVersions
