import {
  Box,
  Button,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import React from "react";
import { AppContext } from "../../../context/AppContext";
import { Localization } from "../../../localization/Localization";
import LicenceServer from "../../../servers/LicenceServer";
import TelemetryServer from "../../../servers/TelemetryServer";
import VersionInfosServer from "../../../servers/VersionInfosServer";
import CollapsableRow from "../../../util/CollapsableRow";
import { ErrorChip, SuccessChip } from "../../../util/CustomChip";
import { DateTime } from "../../../util/DateTime";
import { SoftwareVersionString } from "../../../util/SoftwareVersionString";

class SingleLicence extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activatedLoaded: false,
      activated: null,

      computerNameLoaded: false,
      computerName: null,

      featuresLoaded: false,
      features: [],

      methodLoaded: false,
      method: null,

      activationDateLoaded: false,
      activationDate: null,

      activationMethodsLoaded: false,
      activationMethods: [],

      validUntilLoaded: false,
      validUntil: null,

      productBranchesLoaded: false,
      productBranches: [{
        branchNumber: null,
        branchNames: []
      }],

      releasableLoaded: false,
      releasable: null,

      licenceForMachineNumberLoaded: false,
      licenceForMachineNumber: null,


      //lastSeenVersionLoaded: false,
      //lastSeenVersion: null,

      isDeactivatedLoaded: false,
      isDeactivated: false,

      licenceValidUntil: null,
      licenceValidUntilLoaded: false,
    };
  }

  isActivated = () => this.state.activated;
  isLoaded = () =>
    this.state.computerNameLoaded &&
    this.state.methodLoaded &&
    this.state.activatedLoaded &&
    this.state.featuresLoaded &&
    this.state.activationDateLoaded &&
    this.state.activationMethodsLoaded &&
    this.state.validUntilLoaded &&
    this.state.productBranchesLoaded &&
    this.state.releasableLoaded &&
    this.state.licenceForMachineNumberLoaded &&
    this.state.isDeactivatedLoaded &&
    this.state.licenceValidUntilLoaded;

  getMethodName = () => {
    if (!this.isActivated()) return "";

    if (this.state.method === 0)
      return <Localization>licences.online</Localization>;

    if (this.state.method === 1)
      return <Localization>licences.offline</Localization>;

    if (this.state.method === 2)
      return <Localization>licences.floating</Localization>;

    return <Localization>licences.none</Localization>;
  };

  getComputerName = () => {
    if (!this.isActivated()) return "";

    return this.state.computerName;
  };

  getActivationMethods = () => {
    let methods = [];

    if (this.state.activationMethods.includes(0)) methods.push("online");
    if (this.state.activationMethods.includes(1)) methods.push("offline");
    if (this.state.activationMethods.includes(2)) methods.push("floating");

    if (methods.length === 0) methods.push("none")

    return methods;
  };

  getStatusChip = () => {
    if (
      this.state.licenceValidUntil != null &&
      moment().isAfter(this.state.licenceValidUntil)
    )
      return (
        <ErrorChip
          size="small"
          label={<Localization>licences.licence_expired</Localization>}
        />
      );

    if (this.state.isDeactivated)
      return (
        <ErrorChip
          size="small"
          label={<Localization>licences.deactivated</Localization>}
        />
      );

    return (
      <SuccessChip
        size="small"
        label={<Localization>licences.activated</Localization>}
      />
    );
  };

  release = () => {
    LicenceServer.post(`api/Licences/${this.props.id}/release`).then((data) => {
      const { appDispatch } = this.context;
      appDispatch({
        type: "showMessage",
        data: {
          severity: "success",
          value: <Localization>licences.licence_released</Localization>,
        },
      });

      this.loadDetails();
      this.loadBranches();
      this.loadFeatures();
      this.loadActivationDate();
      this.loadMethod();
      this.loadComputerName();
      this.loadValidUntil();
    });
  };

  loadDetails = () => {
    LicenceServer.get(`api/Licences/${this.props.id}`).then((data) => {
        this.setState({
          activatedLoaded: true,
          activated: data.activated,
          activationMethodsLoaded: true,
          activationMethods: data.activationMethods,
          releasable: data.releasable,
          releasableLoaded: true,
          licenceForMachineNumber: data.licenceForMachineNumber,
          licenceForMachineNumberLoaded: true,
          isDeactivated: data.isDeactivated,
          isDeactivatedLoaded: true,
          licenceValidUntil: data.licenceValidUntil,
          licenceValidUntilLoaded: true,
          licenceForMachineNumberLoaded: true,
        })
      }
    );
    /*TelemetryServer.get(`api/GetLatestVersionForLicenceGUID/${this.props.id}`)
      .then((data) =>
        this.setState({
          lastSeenVersionLoaded: true,
          lastSeenVersion: (
            <>
              <SoftwareVersionString
                versionType={data.versionType}
                versionIteration={data.versionIteration}
                versionNumber={data.versionNumber}
              />
            </>
          ),
        })
      )
      .catch((error) =>
        this.setState({
          lastSeenVersionLoaded: true,
          lastSeenVersion: null,
        })
      );*/
  };

  loadBranches = () => {
    let allBranches = [];

    VersionInfosServer.get(`rest/${this.props.product}/GetProductBranchesDisplayNames`).then((branchData) => {
        for(const branchId of this.props.licenceBranches)
        {
          const branchDisplayNames = branchData[branchId];
          if(branchDisplayNames)
          {
            allBranches.push({ branchNumber: branchId, branchNames: branchDisplayNames })
          }
        }

        allBranches.sort((a, b) => {
          return b.branchNumber - a.branchNumber;
        });

        this.setState({ productBranchesLoaded: true, productBranches: allBranches})
      }
    )
  }

  loadFeatures = () => {
    LicenceServer.get(`api/Licences/${this.props.id}/features`).then((data) =>
      this.setState({ featuresLoaded: true, features: data })
    );
  };

  loadActivationDate = () => {
    LicenceServer.get(`api/Activations/${this.props.id}/current/at`)
      .then((data) =>
        this.setState({
          activationDateLoaded: true,
          activationDate: data,
        })
      )
      .catch((error) => {
        this.setState({ activationDateLoaded: true, activationDate: null });
      });
  };

  loadMethod = () => {
    //ak je aktivovana
    LicenceServer.get(`api/Activations/${this.props.id}/current/method`)
      .then((data) => {
        this.setState({ methodLoaded: true, method: data });
      })
      .catch((error) => {
        this.setState({ methodLoaded: true, method: null });
      });
  };

  loadComputerName = () => {
    LicenceServer.get(`api/Activations/${this.props.id}/current/computers`)
      .then((data) => {
        this.setState({
          computerNameLoaded: true,
          computerName: data[0].computerName,
        });
      })
      .catch((error) => {
        this.setState({ computerNameLoaded: true, method: null });
      });
  };

  loadValidUntil = () => {
    //ak je aktivovana
    LicenceServer.get(`api/Activations/${this.props.id}/current/validUntil`)
      .then((data) => {
        this.setState({ validUntilLoaded: true, validUntil: data });
      })
      .catch((error) => {
        this.setState({ validUntilLoaded: true, validUntil: null });
      });
  };

  componentDidMount() {
    this.loadDetails();
    this.loadBranches();
    this.loadFeatures();
    this.loadActivationDate();
    this.loadMethod();
    this.loadComputerName();
    this.loadValidUntil();
  }

  render() {
    if (!this.isLoaded()) return <Skeleton variant="rect" />;

    return (
      <CollapsableRow
        cells={[
          this.props.displayName,
          this.getComputerName(),
          this.getMethodName(),
          this.getStatusChip(),
        ]}
      >
        <Grid container alignItems="flex-end" justify="space-between">
          <Grid item md={8}>
            {this.state.licenceForMachineNumber != null && (
              <Typography gutterBottom>
                <Chip
                  size="small"
                  color="primary"
                  label={<Localization>licences.machine_licence</Localization>}
                />
              </Typography>
            )}

            <Grid container>
              <Grid item md={12}>
                <Box mb={2}>
                  <Table size="small">
                    <TableBody>
                      {this.state.licenceForMachineNumber != null && (
                        <TableRow>
                          <TableCell>
                            <Localization>licences.machine_number</Localization>
                          </TableCell>
                          <TableCell align="right">
                            {this.state.licenceForMachineNumber}
                          </TableCell>
                        </TableRow>
                      )}
                      {this.state.licenceValidUntil != null && (
                        <TableRow>
                          <TableCell>
                            <Localization>
                              licences.limited_validity_until
                            </Localization>
                          </TableCell>
                          <TableCell align="right">
                            <DateTime
                              from={moment(this.state.licenceValidUntil)}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell>
                          <Localization>
                            licences.allowed_activation_methods
                          </Localization>
                        </TableCell>
                        <TableCell align="right">
                          {this.getActivationMethods()
                            .map((method) => (
                              <Localization>{`licences.${method}`}</Localization>
                            ))
                            .reduce((prev, curr) => [prev, "/", curr])}
                        </TableCell>
                      </TableRow>

                      {/*<TableRow>
                        <TableCell>
                          <Localization>
                            licences.last_seen_version
                          </Localization>
                        </TableCell>
                        <TableCell align="right">
                          {this.state.lastSeenVersion ?? (
                            <Localization>
                              licences.version_unknown
                            </Localization>
                          )}
                        </TableCell>
                          </TableRow>*/}

                      {this.isActivated() && (
                        <>
                          <TableRow>
                            <TableCell>
                              <Localization>
                                licences.activation_date
                              </Localization>
                            </TableCell>
                            <TableCell align="right">
                              <DateTime from={this.state.activationDate} />
                            </TableCell>
                          </TableRow>
                          {this.state.method === 1 && (
                            <TableRow>
                              <TableCell>
                                <Localization>
                                  licences.prolongation_until
                                </Localization>
                              </TableCell>
                              <TableCell align="right">
                                <DateTime
                                  from={moment(this.state.validUntil)}
                                />
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                      <TableRow>
                        <TableCell>
                          <Localization>licences.allowed_branches</Localization>
                        </TableCell>
                        <TableCell align="right">
                        {this.state.productBranches.map(branch => branch.branchNames).flat().join(" / ")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Localization>licences.releasable</Localization>
                        </TableCell>
                        <TableCell align="right">
                          {this.state.releasable ? (
                            <Localization>util.yes</Localization>
                          ) : (
                            <Localization>util.no</Localization>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            </Grid>

            {this.state.features.length > 0 && (
              <>
                <Localization>licences.features</Localization>
                {": "}
              </>
            )}

            <Typography gutterBottom>
              {this.state.features.map((feature, index) => {
                return (
                  <>
                    <Chip
                      key={index}
                      size="small"
                      color="secondary"
                      label={feature.displayName}
                    />{" "}
                  </>
                );
              })}
            </Typography>
          </Grid>
          <Grid item>
            {this.state.activated && this.state.releasable && (
              <Button
                onClick={this.release}
                startIcon={<ClearIcon />}
                size="small"
                color="primary"
                variant="contained"
              >
                <Localization>licences.release</Localization>
              </Button>
            )}
          </Grid>
        </Grid>
      </CollapsableRow>
    );
  }
}

SingleLicence.contextType = AppContext;

export default SingleLicence;
