import React, { useState } from "react"
import Stepper from "@material-ui/core/Stepper"
import UploadActivationRequestFileStep from "./steps/UploadActivationRequestFileStep"
import SelectLicenceToActivateStep from "./steps/SelectLicenceToActivateStep"
import LicenceBeingActivatedStep from "./steps/LicenceBeingActivatedStep"
import LicenceActivatedReadyToDownloadStep from "./steps/LicenceActivatedReadyToDownloadStep"
import { ManualActivationContextComponent } from "./ManualActivationContext"
import { Step, StepLabel, StepContent } from "@material-ui/core"
import { Localization } from "../localization/Localization"

function ManualActivationStepper() {
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep(prev => prev + 1)
  }

  const restartStepper = () => {
    setActiveStep(0);
  }

  return (
    <>
      <ManualActivationContextComponent>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel>
              <Localization>
                manual_activation.select_request_file_title
              </Localization>
            </StepLabel>
            <StepContent>
              <UploadActivationRequestFileStep onNext={handleNext} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <Localization>
                manual_activation.select_licence_title
              </Localization>
            </StepLabel>
            <StepContent>
              <SelectLicenceToActivateStep onNext={handleNext} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <Localization>
                manual_activation.activation_creation_file_title
              </Localization>
            </StepLabel>
            <StepContent>
              <LicenceBeingActivatedStep onNext={handleNext} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              <Localization>
                manual_activation.download_activation_file
              </Localization>
            </StepLabel>
            <StepContent>
              <LicenceActivatedReadyToDownloadStep onNext={restartStepper} />
            </StepContent>
          </Step>
        </Stepper>
      </ManualActivationContextComponent>
    </>
  )
}

export default ManualActivationStepper
