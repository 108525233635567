import React, { useContext } from "react"

import {
  CardMedia,
  Grid,
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core"
import logo from "../assets/microstep-logo.png"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import { UserContext } from "../context/UserContext"
import { useEffect } from "react"

import { Localization, Localizator } from "../localization/Localization"
import { LocalizationContext } from "../context/LocalizationContext"

function Header() {
  const { userState, userDispatch } = useContext(UserContext)

  return (
    <>
      <Box mt={3} mb={4}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item sm={3}>
            <CardMedia component="img" image={logo} title="Logo" />
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <Box mr={2}>
                  <Typography variant="body1" color="primary">
                    {userState.loggedUser.user.fullname}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    userDispatch({
                      type: "logout"
                    })
                  }}
                  size="small"
                  variant="outlined"
                  color="primary"
                  startIcon={<ExitToAppIcon />}
                >
                  <Localization>header.logout</Localization>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Header
