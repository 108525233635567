import React, { useEffect, useState, useContext } from "react";

import CollapsableRow from "../../../util/CollapsableRow";
import { DateTime } from "../../../util/DateTime";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import DownloadButton from "../../../util/DownloadButton";

import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import VersionInfosServer from "../../../servers/VersionInfosServer";
import { SoftwareContext } from "../../../context/SoftwareContext";
import { Version, ReleaseNote } from "../../../types";

interface Props {
  version: Version;
}

const PreviousVersionRow = ({ version }: Props) => {
  const [releaseNotes, setReleaseNotes] = useState<ReleaseNote[]>([]);

  const { product, branch } = useContext(SoftwareContext).softwareState;
  useEffect(() => {
    async function fetch() {
      const data = await VersionInfosServer.get(
        `rest/${product}/${branch.number}/${branch.platform}/Versions/${version.number}/${version.type}/${version.iteration}/ReleaseNotes`
      );

      setReleaseNotes(data);
    }

    fetch();
  }, [version]);

  return (
    <>
      <CollapsableRow
        cells={[
          "#" + version.number,
          "#" + version.iteration,
          <DateTime from={version.releaseDate} />,
          <DownloadButton to={version.downloadUrl} size="small" />,
        ]}
      >
        <List dense={true}>
          {releaseNotes.map((note, index) => {
            return (
              <ListItem key={index}>
                <ListItemIcon>
                  <KeyboardArrowRightIcon />
                </ListItemIcon>
                <ListItemText primary={note.String} />
              </ListItem>
            );
          })}
        </List>
      </CollapsableRow>
    </>
  );
};

export default PreviousVersionRow;
