import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import { Skeleton } from "@material-ui/lab"
import React from "react"
import { Localization } from "../../../localization/Localization"
import LicenceServer from "../../../servers/LicenceServer"
import VersionInfosServer from "../../../servers/VersionInfosServer"
import { ReleaseNote } from "../../../types";

interface Props {
  releaseNotes: ReleaseNote[]
}

const LatestVersionReleaseNotes = ({ releaseNotes }: Props) => {
  return (
    <>
      <Typography variant="h6" color="primary">
        {releaseNotes ? (
          <Localization>downloads.latest_release_notes</Localization>
        ) : (
          <Skeleton />
        )}
      </Typography>

      {releaseNotes ? (
        <List dense={true}>
          {releaseNotes.map((note, index) => {
            return (
              <ListItem key={index}>
                <ListItemIcon>
                  <KeyboardArrowRightIcon />
                </ListItemIcon>
                <ListItemText primary={note.String} />
              </ListItem>
            )
          })}
        </List>
      ) : (
        <Skeleton height={120} />
      )}
    </>
  )
}

export default LatestVersionReleaseNotes
