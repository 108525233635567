import Axios from "axios"
import UserLoggedIn from "./UserLoggedIn"

class Oidc {
  static login(username, password) {
    return Axios.post(
      "https://auth.microstep.sk/connect/token",
      CreateTokenRequest(username, password)
    ).then(res => {
      return new UserLoggedIn(res.data.access_token)
    })
  }
  static setupAxios(token) {
    Axios.defaults.headers.common["Authorization"] = "Bearer " + token
  }
}

function CreateTokenRequest(username, password) {
  const request = new URLSearchParams()
  request.append("client_id", "MSCustomerPortal")
  request.append("response_type", "code id_token")
  request.append("scope", "msusermanagement mslicenceserver telemetry")
  request.append("grant_type", "password")
  request.append("username", username)
  request.append("password", password)
  return request
}

export default Oidc
