import React from "react"
import {
  FormControl,
  InputLabel,
  Box,
  Input,
  InputAdornment,
  Button,
  Backdrop
} from "@material-ui/core"
import PersonRoundedIcon from "@material-ui/icons/PersonRounded"
import VpnKeyRoundedIcon from "@material-ui/icons/VpnKeyRounded"

import CircularProgress from "@material-ui/core/CircularProgress"
import { UserContext } from "../context/UserContext"

import { withStyles } from "@material-ui/core/styles"
import { PasswordInput } from "../util/PasswordInput"

const LoadingBackdrop = withStyles(
  theme => ({
    root: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff"
    }
  }),
  { withTheme: true }
)(Backdrop)

class LoginForm extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props)
    this.props = props
    this.state = { username: "", password: "", backdrop: false }
  }

  componentDidMount() {
    this.previousContext = this.context
  }

  handleLogin = () => {
    const { userDispatch } = this.context
    this.setState({ backdrop: true })
    userDispatch({ type: "loginWithCredentials", data: this.state })
  }

  componentDidUpdate() {
    if (
      this.previousContext.userState.loginAttemptResults !==
      this.context.userState.loginAttemptResults
    ) {
      if ([...this.context.userState.loginAttemptResults].pop() == true) {
        this.setState({ backdrop: false })
      } else {
        this.setState({ backdrop: false })
      }
    }

    this.previousContext = this.context
  }

  render() {
    return (
      <>
        <LoadingBackdrop open={this.state.backdrop}>
          <CircularProgress color="inherit" />
        </LoadingBackdrop>

        <form
          method="post"
          action="/login"
          autoComplete="on"
          onSubmit={e => {
            e.preventDefault()
            this.handleLogin()
          }}
        >
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="username">User name</InputLabel>
            <Input
              id="username"
              value={this.state.username}
              onChange={e => {
                this.setState({ username: e.target.value })
              }}
              name="username"
              autoComplete="on"
              autoFocus
              startAdornment={
                <InputAdornment position="start">
                  <PersonRoundedIcon color="primary" />
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <PasswordInput
              value={this.state.password}
              onChange={e => {
                this.setState({ password: e.target.value })
              }}
              autoComplete="on"
            />
          </FormControl>
          <Box mt={3}>
            <Button
              onClick={this.handleLogin}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Sign in
            </Button>
          </Box>
        </form>
      </>
    )
  }
}

export default LoginForm
