import React, { useEffect } from "react";
import {
  Button,
  TextField,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@material-ui/core";
import LicenceServer from "../../servers/LicenceServer";
import DeleteIcon from "@material-ui/icons/Delete";
import { AppContext } from "../../context/AppContext";
import { DateTime } from "../../util/DateTime";
import { Authorize } from "../../util/Authorize";
import { Localization } from "../../localization/Localization";

class ManageUserSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptions: [],
      subscriptionsLoaded: false,
    };
  }

  componentDidMount() {
    LicenceServer.get(`api/ManageSubscriptions/of/${this.props.user}`).then(
      (data) => {
        this.setState({ subscriptions: data, subscriptionsLoaded: true });
        console.log(data);
      }
    );
  }

  subscriptionState(dateFrom, dateUntil) {
    let now = new Date();
    if (new Date(dateUntil) < now)
      return <Localization>users.subscription_expired</Localization>;
    if (new Date(dateFrom) > now)
      return <Localization>users.subscription_future</Localization>;
    return <Localization>users.subscription_active</Localization>;
  }

  deleteSubscription = (id) => {
    LicenceServer.post(`api/ManageSubscriptions/${id}/delete`)
      .then((data) => {
        const { appDispatch } = this.context;
        appDispatch({
          type: "showMessage",
          data: {
            severity: "success",
            value: "Subscription deleted successfuly.",
          },
        });

        this.setState((state, props) => {
          return {
            subscriptions: state.subscriptions.filter(
              (sub) => sub.subscriptionId !== id
            ),
          };
        });
      })
      .catch((error) => {
        const { appDispatch } = this.context;
        appDispatch({
          type: "showMessage",
          data: {
            severity: "error",
            value: "Subscription deletion failed.",
          },
        });
      });
  };

  render() {
    return (
      <>
        <TableContainer component={Paper} elevation={5}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Localization>users.subscription_valid_from</Localization>
                </TableCell>
                <TableCell>
                  <Localization>users.subscription_valid_until</Localization>
                </TableCell>
                <TableCell>
                  <Localization>users.subscription_state</Localization>
                </TableCell>
                <TableCell>
                  <Localization>users.subscription_note</Localization>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.subscriptions.map((subscription) => (
                <TableRow key={subscription.subscriptionId}>
                  <TableCell>
                    <DateTime from={subscription.validFrom} />
                  </TableCell>
                  <TableCell>
                    <DateTime from={subscription.validUntil} />
                  </TableCell>
                  <TableCell>
                    {this.subscriptionState(
                      subscription.validFrom,
                      subscription.validUntil
                    )}
                  </TableCell>
                  <TableCell>{subscription.note}</TableCell>
                  <TableCell align="right">
                    <Authorize
                      sufficientRole={["UserAdmin"]}
                      yes={
                        <Button
                          onClick={() =>
                            this.deleteSubscription(subscription.subscriptionId)
                          }
                          size="small"
                          variant="contained"
                          color="secondary"
                          startIcon={<DeleteIcon />}
                        >
                          <Localization>util.delete</Localization>
                        </Button>
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

ManageUserSubscription.contextType = AppContext;

export default ManageUserSubscription;
