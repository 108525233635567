import React, { useEffect, useState } from "react"

import { Card, CardMedia, Box, CardContent } from "@material-ui/core"
import logo from "../assets/microstep-logo.png"
import LoginForm from "./LoginForm"

const LoginCard = () => {
  return (
    <Card elevation={2}>
      <Box p={2}>
        <CardMedia component="img" image={logo} title="Logo" />
      </Box>
      <CardContent>
        <LoginForm />
      </CardContent>
    </Card>
  )
}

export default LoginCard
