import React from "react"
import { Snackbar } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"
import { AppContext } from "../context/AppContext"

class Message extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: true }
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.open}
          autoHideDuration={5000}
          onClose={this.handleClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={this.props.severity}
            onClose={this.handleClose}
          >
            {this.props.value}
          </MuiAlert>
        </Snackbar>
      </>
    )
  }
}

function Messages() {
  return (
    <>
      <AppContext.Consumer>
        {context =>
          context.appState.messages.map((msg, index) => (
            <Message key={index} severity={msg.severity} value={msg.value} />
          ))
        }
      </AppContext.Consumer>
    </>
  )
}

export default Messages
