import React, { useEffect, useState } from "react"
import { Box, Grid } from "@material-ui/core"
import LoginCard from "./LoginCard"

function LoginScreen() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={4}>
        <LoginCard />
      </Grid>
    </Grid>
  )
}

export default LoginScreen
