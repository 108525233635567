import React, { useEffect } from "react";
import { TextField, Box, Button, Grid, Typography } from "@material-ui/core";
import UsersTable from "./UsersTable";
import UserManagementServer from "../servers/UserManagementServer";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CreateUserDialog from "./CreateUserDialog";
import { EditUserDialog } from "./EditUserDialog";
import LicenceServer from "../servers/LicenceServer";
import { Authorize } from "../util/Authorize";
import { Localization } from "../localization/Localization";

class AllUsersScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      usersLoaded: false,

      filteredUsers: [],
      filter: "",

      userToComputers: {},
      userToComputersLoaded: false,

      createUserDialogOpen: false,
    };
  }

  componentDidMount() {
    UserManagementServer.get("api/ManageUsers/all").then((data) => {
      this.setState({ users: data, filteredUsers: data, usersLoaded: true });
    });

    LicenceServer.get("api/ManageActivations/allComputers").then((data) => {
      this.setState({
        userToComputers: data.computers,
        userToComputersLoaded: true,
      });
    });
  }

  onFilterChange = (event) => {
    this.setState({ filter: event.target.value });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filter !== this.state.filter) {
      if (this.state.filter === "")
        this.setState((state, props) => ({ filteredUsers: state.users }));

      this.setState((state, props) => {
        return {
          filteredUsers: state.users.filter(
            (user) =>
              (user.userName ?? "")
                .toLowerCase()
                .includes(state.filter.toLowerCase()) ||
              (user.fullName ?? "")
                .toLowerCase()
                .includes(state.filter.toLowerCase()) ||
              (user.email ?? "")
                .toLowerCase()
                .includes(state.filter.toLowerCase()) ||
              (user.id ?? "").toLowerCase() == state.filter.toLowerCase() ||
              (user.machines ?? []).some((machine) =>
                machine
                  .toString()
                  .toLowerCase()
                  .includes(state.filter.toLowerCase())
              ) ||
              (user.id in state.userToComputers &&
                state.userToComputers[user.id].some((computer) =>
                  computer.computerName
                    .toLowerCase()
                    .includes(state.filter.toLowerCase())
                ))
          ),
        };
      });
    }
  }

  render() {
    return (
      <>
        <Typography variant="h4" color="primary" gutterBottom>
          <Localization>users.users</Localization>
        </Typography>

        <Authorize
          sufficientRole={["UserAdmin"]}
          yes={
            <Box mb={2}>
              <Grid container justify="flex-end">
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => {
                      this.setState({ createUserDialogOpen: true });
                    }}
                  >
                    <Localization>users.create_user</Localization>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          }
        />

        <TextField
          fullWidth
          id="outlined-basic"
          label={<Localization>users.search</Localization>}
          value={this.state.filter}
          onChange={this.onFilterChange}
          variant="outlined"
          autoComplete="off"
        />
        <Box mt={1} />
        <UsersTable
          loaded={this.state.usersLoaded && this.state.userToComputersLoaded}
          users={this.state.filteredUsers}
        />

        <Authorize
          sufficientRole={["UserAdmin"]}
          yes={
            <CreateUserDialog
              open={this.state.createUserDialogOpen}
              close={() => {
                this.setState({ createUserDialogOpen: false });
              }}
            />
          }
        />
      </>
    );
  }
}

export default AllUsersScreen;
