import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Route, Switch, withRouter } from "react-router-dom";
import { SoftwareContextComponent } from "../context/SoftwareContext";
import { Localization } from "../localization/Localization";
import LicenceServer from "../servers/LicenceServer";
import VersionInfosServer from "../servers/VersionInfosServer";
import Documentations from "./Documentations/Documentations";
import Downloads from "./Downloads/Downloads";
import Licences from "./Licences/Licences";
import Newsletters from "./Newsletters/Newsletters";
import SoftwareBranchSelector from "./SoftwareBranchSelector";
import { BranchAndPlatform, Product } from "../types";

interface State {
  loaded: boolean;
  currentTab: string;
  productDisplayName: string;
  productLogo: string;
  productDescription: string;
  productBranches: BranchAndPlatform[];
  productIsFree: boolean;
}

interface MatchParams {
  product: Product;
}

class SoftwareScreen extends React.Component<
  RouteComponentProps<MatchParams>,
  State
> {
  public product: Product;

  constructor(props: RouteComponentProps<MatchParams>) {
    super(props);
    this.product = props.match.params.product;
    this.state = {
      loaded: false,
      currentTab: props.location.pathname,
    } as State;
  }

  async componentDidMount() {
    const productDisplayName: string = await VersionInfosServer.get(
      `rest/${this.product}/DisplayName`
    );
    const productLogo: string = await VersionInfosServer.get(
      `rest/${this.product}/Base64EncodedSVGLogo`
    );
    const productDescription: string = await VersionInfosServer.get(
      `rest/${this.product}/Description`
    );

    const productIsFree: boolean = await LicenceServer.get(
      `api/products/${this.product}/free`
    );

    let allBranches: BranchAndPlatform[] = await LicenceServer.get(
      `api/products/${this.product}/branches`
    );

    allBranches.sort((a, b) => {
      let numberDiff = b.number - a.number;
      let platformDiff = b.platform - a.platform;

      return numberDiff != 0 ? numberDiff : platformDiff;
    });

    let productBranches: BranchAndPlatform[] = [];

    for (const branch of allBranches) {
      const isDeprecated = await VersionInfosServer.get(
        `rest/${this.product}/${branch.number}/${branch.platform}/IsDeprecated`
      );

      if (!isDeprecated) productBranches.push(branch);
    }

    for (let branch of productBranches) {
      const displayName = await VersionInfosServer.get(
        `rest/${this.product}/${branch.number}/${branch.platform}/DisplayName`
      );
      branch.displayName = displayName;
    }

    this.setState({
      loaded: true,
      productDisplayName,
      productLogo,
      productDescription,
      productBranches,
      productIsFree,
    });
  }

  handleChange = (e: any, newValue: any) => {
    this.setState({ currentTab: newValue });
    this.props.history.push(newValue);
  };

  render() {
    if (!this.state.loaded)
      return (
        <>
          <Skeleton variant="rect" height={300} />
        </>
      );

    return (
      <SoftwareContextComponent
        defaultState={{
          product: this.product,
          branch: this.state.productBranches[0],
          type: 3,
        }}
      >
        <Paper elevation={0}>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Card elevation={0}>
                <Box ml={3} mr={3} mt={1}>
                  {this.state.loaded ? (
                    <CardMedia
                      src={`data:image/svg+xml;base64,${this.state.productLogo}`}
                      component="img"
                      title={this.state.productDisplayName}
                    ></CardMedia>
                  ) : (
                    <Skeleton variant="rect" height={170} />
                  )}
                </Box>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {this.state.loaded ? (
                      this.state.productDisplayName
                    ) : (
                      <Skeleton />
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {this.state.loaded ? (
                      this.state.productDescription
                    ) : (
                      <Skeleton />
                    )}
                  </Typography>

                  <SoftwareBranchSelector
                    branches={this.state.productBranches}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={9}>
              <Paper elevation={0}>
                <Tabs
                  value={this.state.currentTab}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab
                    label={<Localization>downloads.title</Localization>}
                    value={`/software/${this.product}`}
                    selected={true}
                  />
                  <Tab
                    label={<Localization>newsletters.title</Localization>}
                    value={`/software/${this.product}/newsletters`}
                  />
                  {!this.state.productIsFree && (
                    <Tab
                      label={<Localization>licences.title</Localization>}
                      value={`/software/${this.product}/licences`}
                    />
                  )}
                  <Tab
                    label={
                      <Localization>
                        documentation_and_tutorials.title
                      </Localization>
                    }
                    value={`/software/${this.product}/documentation`}
                  />
                </Tabs>
                <Box p={2}>
                  <Switch>
                    <Route path="/software/:product/newsletters">
                      <Newsletters />
                    </Route>
                    {!this.state.productIsFree && (
                      <Route path="/software/:product/licences">
                        <Licences />
                      </Route>
                    )}
                    <Route path="/software/:product/documentation">
                      <Documentations />
                    </Route>
                    <Route path="/software/:product">
                      <Downloads />
                    </Route>
                  </Switch>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </SoftwareContextComponent>
    );
  }
}

export default withRouter(SoftwareScreen);
