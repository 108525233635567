import React, { useEffect, useContext } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { UserContext } from "../context/UserContext"

interface Match {
  token: string
}

const LoginImmediately = ({ match, history }: RouteComponentProps<Match>) => {
  console.log(match)

  const { userDispatch } = useContext(UserContext)

  useEffect(() => {
    userDispatch({
      type: "loginWithToken",
      data: { token: match.params.token }
    })
    history.push("/")
  }, [])

  return <></>
}

export default withRouter(LoginImmediately)
