class User {
  constructor(id, username, fullname, email, roles, language) {
    this.id = id
    this.username = username
    this.fullname = fullname
    this.email = email
    this.roles = roles
    this.language = language
  }

  id() {
    return this.id
  }

  username() {
    return this.username
  }

  fullname() {
    return this.fullname
  }

  email() {
    return this.email
  }
}

export default User
