import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core"
import React, { useContext } from "react"
import { SoftwareContext } from "../context/SoftwareContext"
import { Localization } from "../localization/Localization"
import { BranchAndPlatform } from "../types";

interface Props {
  branches: BranchAndPlatform[]
}

const SoftwareBranchSelector = ({ branches }: Props) => {
  const { softwareState, setSoftwareState } = useContext(SoftwareContext)

  let distinctBranchNumbers: number[] = [
    ...(new Set(branches.map(b => b.number)) as any)
  ]

  let distinctBranchPlatforms: number[] = [
    ...(new Set(branches.map(b => b.platform)) as any)
  ]

  const branchNumberToDisplayName = (branchNumber: number) =>
    branches.find(b => b.number == branchNumber)?.displayName

  const onBranchChange = (event: any) => {
    setSoftwareState({
      ...softwareState,
      branch: { ...softwareState.branch, number: event.target.value }
    })
  }

  const onPlatformChange = (event: any) => {
    setSoftwareState({
      ...softwareState,
      branch: { ...softwareState.branch, platform: event.target.value }
    })
  }

  const onTypeChange = (event: any) => {
    setSoftwareState({ ...softwareState, type: event.target.value })
  }

  return (
    <>
      {distinctBranchNumbers.length > 1 && (
        <Box mt={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              <Localization>software.branch</Localization>
            </InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={softwareState.branch.number}
              onChange={onBranchChange}
              label="Branch"
            >
              {distinctBranchNumbers.map((number, index) => {
                return (
                  <MenuItem value={number}>
                    {branchNumberToDisplayName(number)}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
      )}
      {distinctBranchPlatforms.length > 1 && (
        <Box mt={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              <Localization>software.platform</Localization>
            </InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={softwareState.branch.platform}
              onChange={onPlatformChange}
              label="Platform"
            >
              <MenuItem value={1}>Win32</MenuItem>
              <MenuItem value={2}>x64</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}
      <Box mt={2}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="demo-simple-select-outlined-label">
            <Localization>software.type</Localization>
          </InputLabel>
          <Select
            fullWidth
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={softwareState.type}
            onChange={onTypeChange}
            label="Type"
          >
            <MenuItem value={3}>
              <Localization>software.stable</Localization>
            </MenuItem>
            <MenuItem value={2}>
              <Localization>software.beta</Localization>
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  )
}

export default SoftwareBranchSelector
