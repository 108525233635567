import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import React, { useEffect, useState } from "react";
import { Localization } from "../localization/Localization";
import UserManagementServer from "../servers/UserManagementServer";
import { MachineNumberChip } from "../util/CustomChip";
import { User, Dealer, Role } from "../types";

export interface UserFormProps {
  onChange: (user: User) => void;
  onCurrentMachineNumber: (currentMachineNumber: string) => void;
  initialUser?: User;
}

export const UserForm = ({
  onChange,
  initialUser,
  onCurrentMachineNumber,
}: UserFormProps) => {
  const [user, setUser] = useState<User>({
    userName: initialUser?.userName ?? "",
    fullName: initialUser?.fullName ?? "",
    email: initialUser?.email ?? "",
    roles: initialUser?.roles ?? ["Customer"],
    dealers: initialUser?.dealers ?? [],
    machines: initialUser?.machines ?? [],
  });

  const [availableRoles, setAvailableRoles] = useState<Role[]>([]);
  const [availableDealers, setAvailableDealers] = useState<Dealer[]>([]);

  const [currentMachineNumber, setCurrentMachineNumber] = useState<string>("");

  useEffect(() => {
    onChange(user);
  }, [user]);

  useEffect(() => {
    onCurrentMachineNumber(currentMachineNumber);
  }, [currentMachineNumber]);

  useEffect(() => {
    UserManagementServer.get(`api/User/GetAllRoles`).then((roles: Role[]) =>
      setAvailableRoles(roles)
    );
    UserManagementServer.get(`api/ManageDealers/all`).then(
      (dealers: Dealer[]) => setAvailableDealers(dealers.sort())
    );
  }, []);

  const getImportantDealersAvailable = (): string[] => {
    const allImportantDeadlers = [
      "MicroStep Europa GmbH", 
      "Wouters Cutting & Welding BVBA", 
      "CSTA"
    ];  //  napr. mozno vyniest ako global?
    return allImportantDeadlers.filter((importantDealer) => availableDealers.includes(importantDealer));
  }

  const getNonImportantDealersAvailable = (): string[] => {
    return availableDealers.filter((dealer) => !getImportantDealersAvailable().includes(dealer));
  }

  const handleRoleCheckbox = (event: any): void => {
    const role: Role = event.target.name;

    if (user.roles.includes(role) && !event.target.checked) {
      setUser(
        (prevUser) =>
          ({
            ...prevUser,
            roles: prevUser.roles.filter((r) => r !== role),
          } as User)
      );
    } else if (!user.roles.includes(role) && event.target.checked) {
      setUser(
        (prevUser) =>
          ({ ...prevUser, roles: prevUser.roles.concat(role) } as User)
      );
    }
  };

  const handleDealerCheckbox = (event: any): void => {
    const dealer: Dealer = event.target.name;

    if (user.dealers.includes(dealer) && !event.target.checked) {
      setUser(
        (prevUser) =>
          ({
            ...prevUser,
            dealers: prevUser.dealers.filter((d) => d !== dealer),
          } as User)
      );
    } else if (!user.dealers.includes(dealer) && event.target.checked) {
      setUser(
        (prevUser) =>
          ({ ...prevUser, dealers: prevUser.dealers.concat(dealer) } as User)
      );
    }
  };

  const isCurrentMachineNumberValid = (): boolean => {
    if (currentMachineNumber == "") return true;

    return currentMachineNumber.match(/^[0-9][0-9][0-9][0-9]$/g) != null;
  };

  const assignCurrentMachineNumber = (): void => {
    if (!isCurrentMachineNumberValid() || currentMachineNumber == "") return;

    setUser(
      (prevUser) =>
        ({
          ...prevUser,
          machines: prevUser.machines.concat(parseInt(currentMachineNumber)),
        } as User)
    );

    setCurrentMachineNumber("");
  };

  return (
    <>
      <TextField
        variant="outlined"
        autoFocus
        margin="dense"
        id="username"
        label={<Localization>users.username</Localization>}
        type="text"
        fullWidth
        autoComplete="off"
        value={user.userName}
        onChange={(e) => {
          const { value } = e.target;
          setUser((prevUser) => ({ ...prevUser, userName: value } as User));
        }}
      />
      <TextField
        variant="outlined"
        margin="dense"
        id="fullname"
        label={<Localization>users.fullname</Localization>}
        type="text"
        fullWidth
        autoComplete="off"
        value={user.fullName}
        onChange={(e) => {
          const { value } = e.target;
          setUser((prevUser) => ({ ...prevUser, fullName: value } as User));
        }}
      />
      <TextField
        variant="outlined"
        margin="dense"
        id="email"
        label={<Localization>users.email</Localization>}
        type="email"
        fullWidth
        autoComplete="off"
        value={user.email}
        onChange={(e) => {
          const { value } = e.target;
          setUser((prevUser) => ({ ...prevUser, email: value } as User));
        }}
      />
      <Box mt={2} mb={2}>
        <Divider variant="fullWidth" />
      </Box>
      <Box mt={1}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="subtitle1" color="primary">
              <strong>{<Localization>users.roles</Localization>}:</strong>
            </Typography>
          </Grid>
          <Grid item>
            <FormGroup row>
              {availableRoles.map((role) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name={role}
                      value={user.roles.includes(role)}
                      defaultChecked={user.roles.includes(role)}
                      onChange={handleRoleCheckbox}
                    />
                  }
                  label={role}
                />
              ))}
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2} mb={2}>
        <Divider variant="fullWidth" />
      </Box>
      <Box mt={1}>
        <Grid container spacing={2}>
          {user.machines.length > 0 && (
            <Grid item md={12}>
              <Typography variant="subtitle1" color="primary">
                <strong>{<Localization>users.machines</Localization>}: </strong>
                {user.machines.map((machine) => (
                  <MachineNumberChip
                    color="secondary"
                    size="small"
                    label={machine}
                    onDelete={(e) => {
                      setUser(
                        (prevUser) =>
                          ({
                            ...prevUser,
                            machines: prevUser.machines.filter(
                              (m) => m != machine
                            ),
                          } as User)
                      );
                    }}
                  />
                ))}
              </Typography>
            </Grid>
          )}
          <Grid container alignItems="flex-start" item md={12} spacing={1}>
            <Grid item>
              <TextField
                error={!isCurrentMachineNumberValid()}
                helperText={
                  !isCurrentMachineNumberValid() ? (
                    <Localization>users.four_digit_number</Localization>
                  ) : (
                    ""
                  )
                }
                id="outlined-basic"
                label="Machine number"
                variant="outlined"
                size="small"
                value={currentMachineNumber}
                autoComplete="off"
                fullWidth={false}
                onChange={(e) => {
                  setCurrentMachineNumber(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <Button
                onClick={assignCurrentMachineNumber}
                color="primary"
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
              >
                <Localization>util.assign</Localization>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2} mb={2}>
        <Divider variant="fullWidth" />
      </Box>
      <Box mt={2} mb={1}>
        <Grid container>
          <Grid item>
            <Box pr={2} pt={1}>
              <Typography variant="subtitle1" color="primary">
                <strong>{<Localization>users.partners</Localization>}:</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid item>
          <FormGroup>
              {getImportantDealersAvailable().map((dealer) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name={dealer}
                      checked={user.dealers.includes(dealer)}
                      onChange={handleDealerCheckbox}
                    />
                  }
                  label={dealer}
                />
              ))}
            </FormGroup>
            <Divider />
            <FormGroup>
              {getNonImportantDealersAvailable().map((dealer) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name={dealer}
                      checked={user.dealers.includes(dealer)}
                      onChange={handleDealerCheckbox}
                    />
                  }
                  label={dealer}
                />
              ))}
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
