import React from "react";

function DateTime(props) {
  var date = new Date(props.from);

  return (
    <>
      {date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()}
    </>
  );
}

function DateTimeDetailed(props) {
  var date = new Date(props.from);
  return (
    <>
      <DateTime from={props.from} />
      {" " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()}
    </>
  );
}

export { DateTime, DateTimeDetailed };
