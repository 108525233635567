import React, { useEffect, useState } from "react";

import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Paper,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";

import HistoryIcon from "@material-ui/icons/History";
import { Localization } from "../localization/Localization";
import LicenceServer from "../servers/LicenceServer";
import { DateTimeDetailed } from "../util/DateTime";
import moment from "moment";
import CollapsableRow from "../util/CollapsableRow";
import { RecordDto, RecordSubject, RecordAction } from "../types";

interface ShowHistoryOfUserProps {
  userName: string;
}

const ShowHistoryOfUser = ({ userName }: ShowHistoryOfUserProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [history, setHistory] = useState<RecordDto[]>([]);
  const [historyLoaded, setHistoryLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!open) {
      setHistory([]);
      setHistoryLoaded(false);
      return;
    }

    LicenceServer.get(`api/ManageHistory/${userName}`).then(
      (data: RecordDto[]) => {
        setHistory(data);

        data.sort((a: RecordDto, b: RecordDto) => {
          return moment(b.date).valueOf() - moment(a.date).valueOf();
        });

        setHistoryLoaded(true);
      }
    );
  }, [open]);

  const subjectToString = (subject: RecordSubject) => {
    switch (subject) {
      case 0:
        return <Localization>users.user</Localization>;
      case 1:
        return <Localization>licences.licence</Localization>;
      case 2:
        return <Localization>subscription.title</Localization>;
      case 3:
        return <Localization>profile.password</Localization>;
    }
    return "";
  };

  const actionToString = (action: RecordAction) => {
    switch (action) {
      case 0:
        return <Localization>util.create</Localization>;
      case 1:
        return <Localization>util.modify</Localization>;
      case 2:
        return <Localization>util.delete</Localization>;
    }
    return "";
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          setOpen(true);
        }}
        startIcon={<HistoryIcon />}
      >
        <Localization>users.show_history</Localization>
      </Button>

      <Dialog
        maxWidth="lg"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>
          <Localization>users.history_title</Localization>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableCell>
                  <Localization>users.date_of_change</Localization>
                </TableCell>
                <TableCell>
                  <Localization>users.subject_of_change</Localization>
                </TableCell>
                <TableCell>
                  <Localization>users.type_of_change</Localization>
                </TableCell>
                <TableCell>
                  <Localization>users.subject_of_change_name</Localization>
                </TableCell>
                <TableCell>
                  <Localization>users.change_performed_by</Localization>
                </TableCell>
                <TableCell></TableCell>
              </TableHead>
              <TableBody>
                {historyLoaded &&
                  history.map((record: RecordDto) => (
                    <CollapsableRow
                      cells={[
                        <DateTimeDetailed from={moment(record.date)} />,
                        subjectToString(record.subject),
                        actionToString(record.action),
                        record.subjectName,
                        record.performedByUserName,
                      ]}
                    >
                      <pre>
                        {record.dto == null
                          ? ""
                          : JSON.stringify(JSON.parse(record.dto!), null, " ")}
                      </pre>
                    </CollapsableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            variant="contained"
          >
            <Localization>util.close</Localization>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShowHistoryOfUser;
