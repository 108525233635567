import React, { useEffect } from "react"
import {
  Step,
  StepLabel,
  StepContent,
  Typography,
  Box,
  Button,
  Paper
} from "@material-ui/core"

import FileUpload from "../../util/FileUpload"
import { useState } from "react"
import { useContext } from "react"
import { ManualActivationContext } from "../ManualActivationContext"
import VersionInfosServer from "../../servers/VersionInfosServer"
import { AppContext } from "../../context/AppContext"
import LicenceServer from "../../servers/LicenceServer"
import { Localization } from "../../localization/Localization"

function UploadActivationRequestFileStep(props) {
  const { manualActivationState, manualActivationDispatch } = useContext(
    ManualActivationContext
  )

  const { appState, appDispatch } = useContext(AppContext)

  const [isRequestFileSet, requestFileSet] = useState(false)

  const isLoaded = () => {
    return isRequestFileSet &&
    manualActivationState.requestFileObject != null
  }

  const [
    requestedProductDisplayName,
    setRequstedProductDisplayName
  ] = useState()

  function requestFileSetter(content, fileName) {
    requestFileSet(true)
    manualActivationDispatch({ type: "setRequestFile", data: content, fileName: fileName })  
  }
    
  useEffect(() => {
    if (manualActivationState.requestFileObject == null) return
    
    VersionInfosServer.get(
      `rest/${manualActivationState.requestFileObject.requestedProduct}/DisplayName`
    ).then(data => {
      setRequstedProductDisplayName(data)
    })
  }, [manualActivationState.requestFileObject])

  return (
    <>
      <Typography gutterBottom>
        <Localization>
          manual_activation.select_request_file_guidance
        </Localization>
      </Typography>
      <FileUpload
        label={
          <Localization>
            manual_activation.select_request_file_button
          </Localization>
        }
        accept=".json"
        fileContentSetter={requestFileSetter}
      />

      {isLoaded() && (
        <Box component={Paper} variant="outlined" mt={1} p={1}>
          <Typography>
            <Localization>licences.product</Localization>:{" "}
            <strong>{requestedProductDisplayName}</strong>
          </Typography>

          <Typography>
            <Localization>licences.computer_name</Localization>:{" "}
            <strong>
              {
                manualActivationState.requestFileObject.requestedComputers[0]
                  .computerName
              }
            </strong>
          </Typography>
        </Box>
      )}

      <Box mt={3}>
        <Button
          onClick={() => {
            if (manualActivationState.requestFileObjectError) {
              appDispatch({
                type: "showMessage",
                data: {
                  severity: "error",
                  value: (
                    <Localization>
                      manual_activation.invalid_request_file
                    </Localization>
                  )
                }
              })
              return
            }

            if (!isLoaded()) {
              appDispatch({
                type: "showMessage",
                data: {
                  severity: "warning",
                  value: (
                    <Localization>
                      manual_activation.please_select_request_file
                    </Localization>
                  )
                }
              })
              return
            }

            props.onNext()
          }}
          variant="contained"
          color="primary"
        >
          <Localization>util.next_button</Localization>
        </Button>
      </Box>
    </>
  )
}

export default UploadActivationRequestFileStep
