import React, { useEffect } from "react"

import { ListItemIcon } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"

const ListItemIconNoSpace = withStyles(() => ({
  root: {
    minWidth: "30px"
  }
}))(ListItemIcon)

function ComponentName() {
  return (
    <>
      <ListItemIconNoSpace>
        <FiberManualRecordIcon style={{ fontSize: 12 }} />
      </ListItemIconNoSpace>
    </>
  )
}

export default ComponentName
