import React, { useState } from "react"
import { Button } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import EditLicenceDialog from "./EditLicenceDialog"

import { Route } from "react-router-dom"
import { Localization } from "../../localization/Localization"

interface EditLicenceProps {
  licenceId: string
}

export const EditLicence = ({ licenceId }: EditLicenceProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  return (
    <>
      <Route>
        <EditLicenceDialog
          open={dialogOpen}
          close={() => {
            setDialogOpen(false)
          }}
          licenceId={licenceId}
        />
      </Route>

      <Button
        size="small"
        variant="contained"
        color="secondary"
        onClick={() => {
          setDialogOpen(true)
        }}
        startIcon={<EditIcon />}
      >
        <Localization>users.licence_edit</Localization>
      </Button>
    </>
  )
}
