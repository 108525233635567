import React from "react";

import { Box, Paper, Typography, Chip } from "@material-ui/core";
import { ActivatedChip, NotActivatedChip } from "../util/CustomChip";
import Axios from "axios";
import { Skeleton } from "@material-ui/lab";
import { DateTime } from "../util/DateTime";
import LicenceServer from "../servers/LicenceServer";
import { Localization } from "../localization/Localization";

class SubscriptionStatusBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionLoaded: false,
      subscriptionState: null,
      validFrom: null,
      validUntil: null,
    };
  }

  componentDidMount() {
    LicenceServer.get("api/subscriptions/last")
      .then((data) => {
        this.setState({
          subscriptionLoaded: true,
          subscriptionState: data.state,
          validUntil: data.validUntil,
          validFrom: data.validFrom,
        });
      })
      .catch((error) => {
        this.setState({ subscriptionLoaded: true, subscriptionState: 0 });
      });
  }

  isExpired = () => {
    return this.state.subscriptionState === 0;
  };

  getStateChip = () => {
    switch (this.state.subscriptionState) {
      case 0:
        return (
          <NotActivatedChip
            label={<Localization>subscription.not_activated</Localization>}
          />
        );
      case 1:
        return (
          <ActivatedChip
            label={<Localization>subscription.activated</Localization>}
          />
        );
      case 2:
        return (
          <ActivatedChip
            label={<Localization>subscription.pre_purchased</Localization>}
          />
        );
    }
  };

  render() {
    return (
      <>
        <Box p={2} component={Paper} elevation={5}>
          <Typography variant="h6" gutterBottom>
            {!this.state.subscriptionLoaded && <Skeleton />}

            {this.state.subscriptionLoaded && "Status: "}
            {this.state.subscriptionLoaded && this.getStateChip()}
          </Typography>
          <Typography variant="h6">
            {!this.state.subscriptionLoaded && <Skeleton />}

            {this.state.subscriptionLoaded && !this.isExpired() && (
              <>
                Validity:{" "}
                <Chip
                  label={
                    <>
                      <DateTime from={this.state.validFrom} /> -{" "}
                      <DateTime from={this.state.validUntil} />
                    </>
                  }
                />
              </>
            )}
          </Typography>
        </Box>
      </>
    );
  }
}

export default SubscriptionStatusBox;
