import React, { useState, useContext } from "react";

import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import UserManagementServer from "../servers/UserManagementServer";
import { AppContext } from "../context/AppContext";
import { UserForm } from "./UserForm";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Localization } from "../localization/Localization";
import LicenceServer from "../servers/LicenceServer";
import moment from "moment";
import { User } from "../types";

interface CreateUserDialogProps extends RouteComponentProps {
  open: boolean;
  close: () => void;
}

const CreateUserDialog = ({ open, close, history }: CreateUserDialogProps) => {
  const [user, setUser] = useState<User>();
  const [currentMachineNumber, setCurrentMachineNumber] = useState<string>("");

  const { appDispatch } = useContext(AppContext);

  const handleClose = () => {
    close();
  };

  const handleSubmit = () => {
    if (user?.userName === "" || user?.fullName === "") {
      appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: "Username and fullname fields must be filled.",
        },
      });
      return;
    }

    if (currentMachineNumber != "") {
      appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: "Machine number must be assigned before creation.",
        },
      });
      return;
    }

    var dto = {
      UserName: user?.userName,
      FullName: user?.fullName,
      Email: user?.email,
      Roles: user?.roles,
      Dealers: user?.dealers,
      Machines: user?.machines,
    };

    UserManagementServer.post(`api/ManageUsers/create`, dto)
      .then((data) => {
        appDispatch({
          type: "showMessage",
          data: {
            severity: "success",
            value: "User added successfuly.",
          },
        });

        close();
        history.push(`/users/${user?.userName}`);

        /* defaultny, 1 mesacny subscription */
        /*LicenceServer.post(`api/ManageSubscriptions/create/${dto.UserName}`, {
          validFrom: moment().toISOString(),
          validUntil: moment().add(1, "M"),
        }).then((d) => {
          close();
          history.push(`/users/${user?.userName}`);
        });*/
      })
      .catch((error) => {
        appDispatch({
          type: "showMessage",
          data: {
            severity: "error",
            value: `User cannot be created: ${error.response.data}`,
          },
        });
      });
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Localization>users.create_user</Localization>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose} color="primary">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          <UserForm
            onChange={(user) => setUser(user)}
            onCurrentMachineNumber={(currentMachineNumber) =>
              setCurrentMachineNumber(currentMachineNumber)
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
          >
            <Localization>util.create</Localization>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

//CreateUserDialog.contextType = AppContext;

export default withRouter(CreateUserDialog);
