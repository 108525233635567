import React, { useEffect, useState } from "react";

import { Typography, Grid, Box } from "@material-ui/core";
import AllSoftwareScreen from "../software/AllSoftwareScreen";
import SoftwareScreen from "../software/SoftwareScreen";
import SubscriptionScreen from "../subscription/SubscriptionScreen";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ManualActivationScreen from "../manual-activation/ManualActivationScreen";
import DashboardScreen from "../dashboard/DashboardScreen";
import ProfileScreen from "../profile/ProfileScreen";
import { SoftwareContextComponent } from "../context/SoftwareContext";
import UsersScreen from "../users/AllUsersScreen";
import UserManageScreen from "../users/UserManageScreen";
import ExportsScreen from "../exports/ExportsScreen";

function Content() {
  return (
    <>
      <Box m={2}>
        <Switch>
          <Route path="/" exact>
            <AllSoftwareScreen />
          </Route>
          <Route path="/software" exact>
            <AllSoftwareScreen />
          </Route>
          <Route path="/software/:product">
            <SoftwareScreen />
          </Route>
          <Route path="/subscription" exact>
            <SubscriptionScreen />
          </Route>
          <Route path="/manual-activation" exact>
            <ManualActivationScreen />
          </Route>
          <Route path="/profile" exact>
            <ProfileScreen />
          </Route>
          <Route path="/users" exact>
            <UsersScreen />
          </Route>
          <Route path="/users/:user" component={UserManageScreen} exact />
          <Route path="/exports" exact>
            <ExportsScreen />
          </Route>
        </Switch>
      </Box>
    </>
  );
}

export default Content;
