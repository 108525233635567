import { Box, Button, Grid, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { Localization } from "../localization/Localization";
import BarChartIcon from "@material-ui/icons/BarChart";
import LicenceServer from "../servers/LicenceServer";
import { AppContext } from "../context/AppContext";
import { saveAs } from "file-saver";

const ExportsScreen = () => {
  const { appState, appDispatch } = useContext(AppContext);

  function announceSuccess() {
    appDispatch({
      type: "showMessage",
      data: {
        severity: "success",
        value: "Generation complete, please open the downloaded file",
      },
    });
  }

  function announceFailure() {
    appDispatch({
      type: "showMessage",
      data: {
        severity: "error",
        value: "Generation failed",
      },
    });
  }

  function announceGenerationStarted() {
    appDispatch({
      type: "showMessage",
      data: {
        severity: "info",
        value: "Generation started, please wait...",
      },
    });
  }

  function generateXLSX(url: string, fileName: string) {
    announceGenerationStarted();
    LicenceServer.get(url, {
      responseType: "arraybuffer",
    })
      .then((data) => {
        var file = new Blob([data]);
        saveAs(file, fileName);

        announceSuccess();
      })
      .catch((err) => {
        announceFailure();
      });
  }

  function generateExpiredSubscriptions() {
    generateXLSX(
      "api/ManageExports/exportExpiredSubscriptions",
      "ExpiredSubscriptions.xlsx"
    );
  }

  function generateExpiredSubscriptions2() {
    generateXLSX(
      "api/ManageExports/exportExpiredSubscriptions2",
      "ExpiredSubscriptions2.zip"
    );
  }

  function exportAllUsers() {
    generateXLSX("api/ManageExports/exportAll", "All.xlsx");
  }

  function exportEmployees() {
    generateXLSX("api/ManageExports/exportEmployees", "Employees.xlsx");
  }

  return (
    <>
      <Box mb={3}>
        <Grid container>
          <Grid item>
            <Typography variant="h4" color="primary">
              <Localization>exports.title</Localization>
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box mb={3}>
        <Grid container>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              startIcon={<BarChartIcon />}
              onClick={generateExpiredSubscriptions2}
            >
              {
                <Localization>
                  exports.export_expired_subscriptions_2
                </Localization>
              }
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <p>
              <Localization>
                exports.export_expired_subscriptions_description_2
              </Localization>
            </p>
          </Grid>
        </Grid>
      </Box>

      <Box mb={3}>
        <Grid container>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              startIcon={<BarChartIcon />}
              onClick={generateExpiredSubscriptions}
            >
              {
                <Localization>
                  exports.export_expired_subscriptions
                </Localization>
              }
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <p>
              <Localization>
                exports.export_expired_subscriptions_description
              </Localization>
            </p>
          </Grid>
        </Grid>
      </Box>

      <Box mb={3}>
        <Grid container>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              startIcon={<BarChartIcon />}
              onClick={exportAllUsers}
            >
              {<Localization>exports.export_all_users</Localization>}
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <p>
              <Localization>exports.export_all_users_description</Localization>
            </p>
          </Grid>
        </Grid>
      </Box>

      <Box mb={3}>
        <Grid container>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              startIcon={<BarChartIcon />}
              onClick={exportEmployees}
            >
              {<Localization>exports.export_employees</Localization>}
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <p>
              <Localization>exports.export_employees_description</Localization>
            </p>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ExportsScreen;
