import React, { useState } from "react"

import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import VpnKeyRoundedIcon from "@material-ui/icons/VpnKeyRounded"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import Input from "@material-ui/core/Input"

export interface PasswordInputProps {
  value: string
  onChange: (e: any) => void
  startAdornment: any
  autoComplete: string
}

export const PasswordInput = ({
  value,
  onChange,
  startAdornment,
  autoComplete
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  return (
    <>
      <Input
        id="standard-adornment-password"
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        autoComplete={autoComplete}
        startAdornment={
          <InputAdornment position="start">
            <VpnKeyRoundedIcon color="primary" />
          </InputAdornment>
        }
      />
    </>
  )
}
