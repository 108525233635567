import React, { useState } from "react"
import { Button } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import EditUserDialog from "./EditUserDialog"

import { Route } from "react-router-dom"
import { Localization } from "../localization/Localization"

interface EditUserProps {
  userName: string
}

export const EditUser = ({ userName }: EditUserProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  return (
    <>
      <Route>
        <EditUserDialog
          open={dialogOpen}
          close={() => {
            setDialogOpen(false)
          }}
          userName={userName}
        />
      </Route>

      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          setDialogOpen(true)
        }}
        startIcon={<EditIcon />}
      >
        <Localization>users.edit_user</Localization>
      </Button>
    </>
  )
}
