import React, { useContext } from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import UpdateIcon from "@material-ui/icons/Update"
import DoneIcon from "@material-ui/icons/Done";
import { ManualActivationContext } from "../ManualActivationContext";
import { saveAs } from "file-saver";
import { Localization } from "../../localization/Localization";

function LicenceActivatedReadyToDownloadStep({ onNext }) {
  const { manualActivationState, manualActivationDispatch } = 
    useContext(ManualActivationContext);

  var resetFile = () => {
    manualActivationDispatch({ type: "setInitialState", data: [] })
    onNext()
  }

  var saveAnswerFile = () => {
    if (manualActivationState.answerFile) {
      var file = new Blob(
        [JSON.stringify(manualActivationState.answerFile, null, 2)],
        {
          type: "text/plain;charset=utf-8",
        }
      );

      //  responseFile_product_yyyy_mm_dd_hh_mm_ss
      var fileName = manualActivationState.requestedFileName.replace(/request/i, "Response")

      //  keby ze...
      if(!fileName.endsWith(".json")) {
        fileName += ".json"
      }

      saveAs(file, fileName);
    }
  };

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={1}>
        <DoneIcon fontSize="large" color="primary" />
      </Grid>
      <Grid item xs={11}>
        <Typography>
          <Localization>manual_activation.activation_successful</Localization>
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Button
          onClick={saveAnswerFile}
          variant="contained"
          color="secondary"
          startIcon={<CloudDownloadIcon />}
        >
          <Localization>util.download_button</Localization>
        </Button>
      </Grid>
      <Grid item xs={2}>
        <Button
          onClick={resetFile}
          variant="contained"
          color="primary"
          startIcon={<UpdateIcon />}
        >
          <Localization>util.restart_button</Localization>
        </Button>
      </Grid>
    </Grid>
  );
}

export default LicenceActivatedReadyToDownloadStep;
