import React, { useEffect } from "react"
import ChangePasswordForm from "./ChangePasswordForm"
import { Grid, Typography, Select, MenuItem, Box } from "@material-ui/core"
import { Localization, Localizator } from "../localization/Localization"
import { LocalizationContext } from "../context/LocalizationContext"
import NewslettersSubscription from "./NewslettersSubscription"

function ProfileScreen() {
  return (
    <>
      <Box mb={2}>
        <Grid container>
          <Grid item md={6}>
            <Typography variant="h4" color="primary" gutterBottom>
              <Localization>profile.change_password_title</Localization>
            </Typography>
            <ChangePasswordForm />
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid container>
          <Grid item md={3}>
            <Box pr={1} pl={2}></Box>
            <Typography variant="h4" color="primary" gutterBottom>
              <Localization>profile.language</Localization>
            </Typography>
            <LocalizationContext.Consumer>
              {value => (
                <Select
                  fullWidth
                  value={value.localizationState.currentLanguage}
                  onChange={e =>
                    value.localizationDispatch({
                      type: "setCurrentLanguage",
                      data: e.target.value
                    })
                  }
                >
                  {Localizator.getAvailableLanguages().map(lang => {
                    return (
                      <MenuItem value={lang} key={lang}>
                        {lang}
                      </MenuItem>
                    )
                  })}
                </Select>
              )}
            </LocalizationContext.Consumer>
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid container>
          <Grid item md={6}>
            <NewslettersSubscription />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ProfileScreen
