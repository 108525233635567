import React, { useContext } from "react"
import { UserContext, UserContextComponent } from "../context/UserContext"
import { Role } from "../types";

export interface AuthorizeProps {
  sufficientRole: Role[]
  yes: React.Component
  no: React.Component
}

export const Authorize = ({ sufficientRole, yes, no }: AuthorizeProps) => {
  const { userState } = useContext(UserContext)
  const roles: Role[] = userState.loggedUser.user.roles

  const authorized: boolean = sufficientRole.some((role: Role) =>
    roles.includes(role)
  )

  return <>{authorized ? yes : no}</>
}
