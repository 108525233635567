import React, { useEffect } from "react"
import { Button } from "@material-ui/core"
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"
import { Link } from "react-router-dom"
import { Localization } from "../localization/Localization"

function DownloadButton(props) {
  const { size, to } = props
  return (
    <Button
      href={to}
      variant="contained"
      color="secondary"
      size={size}
      startIcon={<CloudDownloadIcon />}
    >
      {props.label ? (
        props.label
      ) : (
        <Localization>util.download_button</Localization>
      )}
    </Button>
  )
}

export default DownloadButton
