import React, { createContext } from "react"
import { useImmerReducer } from "use-immer"
import { useEffect } from "react"
import LicenceServer from "../servers/LicenceServer"

const ManualActivationContextInitialState = {
  requestFileContent: null,
  requestFileObject: null,
  requestFileObjectError: null,
  requestedProduct: null,
  requestedFileName: null,
  requestedAt: null,
  selectedLicence: null,
  answerFile: null
}

function ManualActivationContextReducer(state, action) {
  switch (action.type) {
    case "setInitialState":
      state = ManualActivationContextInitialState
      state.requestFileObject = null
    case "setRequestFile":
      state.requestFileContent = action.data
      state.requestedFileName = action.fileName
      break
    case "setRequestFileObject":
      state.requestFileObject = action.data
      state.requestedProduct = state.requestFileObject.requestedProduct
      state.requestedAt = action.data.requestedAt
      break
    case "setRequestFileObjectError":
      state.requestFileObjectError = action.data
      break
    case "setSelectedLicence":
      state.selectedLicence = action.data
      break
    case "setAnswerFile":
      state.answerFile = action.data
      break
  }
}

const ManualActivationContext = createContext()

function ManualActivationContextComponent(props) {
  const [manualActivationState, manualActivationDispatch] = useImmerReducer(
    ManualActivationContextReducer,
    ManualActivationContextInitialState
  )

  useEffect(() => {
    if (manualActivationState.requestFileContent) {
      try {
        const obj = JSON.parse(manualActivationState.requestFileContent)
        LicenceServer.post(`api/activations/request/verify`, obj)
          .then(data => {
            manualActivationDispatch({
              type: "setRequestFileObject",
              data: obj
            })
            manualActivationDispatch({
              type: "setRequestFileObjectError",
              data: null
            })
          })
          .catch(error => {
            manualActivationDispatch({
              type: "setRequestFileObjectError",
              data: "Request file is invalid."
            })
          })
      } catch (error) {
        manualActivationDispatch({
          type: "setRequestFileObjectError",
          data: "Request file is invalid."
        })
      }
    }
  }, [manualActivationState.requestFileContent])

  return (
    <ManualActivationContext.Provider
      value={{ manualActivationState, manualActivationDispatch }}
    >
      {props.children}
    </ManualActivationContext.Provider>
  )
}

export { ManualActivationContext, ManualActivationContextComponent }
