import {
  Box,
  Button,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import moment from "moment";
import React from "react";
import { AppContext } from "../../../context/AppContext";
import { Localization } from "../../../localization/Localization";
import LicenceServer from "../../../servers/LicenceServer";
import TelemetryServer from "../../../servers/TelemetryServer";
import VersionInfosServer from "../../../servers/VersionInfosServer";
import { Authorize } from "../../../util/Authorize";
import CollapsableRow from "../../../util/CollapsableRow";
import { DateTime } from "../../../util/DateTime";
import { SoftwareVersionString } from "../../../util/SoftwareVersionString";
import { EditLicence } from "../EditLicence";
import { ErrorChip, SuccessChip } from "../../../util/CustomChip";
import BlockIcon from "@material-ui/icons/Block";
import CheckIcon from "@material-ui/icons/Check";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { saveAs } from "file-saver";

class Licence extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState();
  }

  defaultState = () => {
    return {
      activatedLoaded: false,
      activated: null,

      computerNameLoaded: false,
      computerName: null,

      featuresLoaded: false,
      features: [],

      methodLoaded: false,
      method: null,

      activationDateLoaded: false,
      activationDate: null,

      validUntilLoaded: false,
      validUntil: null,

      activationMethodsLoaded: false,
      activationMethods: [],

      productBranchesLoaded: false,
      productBranches: [],

      productBranchesDisplayNamesLoaded: false,
      productBranchesDisplayNames: [],

      releasable: null,
      releasableLoaded: false,

      licenceForMachineNumber: null,
      licenceForMachineNumberLoaded: false,

      lastSeenVersion: null,
      lastSeenVersionLoaded: false,

      isDeactivatedLoaded: false,
      isDeactivated: false,

      isNoteLoaded: false,
      note: null,

      isLicenceValidUntilLoaded: false,
      licenceValidUntil: null,
    };
  };

  isActivated = () => this.state.activated;
  isLoaded = () =>
    this.state.computerNameLoaded &&
    this.state.methodLoaded &&
    this.state.activatedLoaded &&
    this.state.featuresLoaded &&
    this.state.activationDateLoaded &&
    this.state.validUntilLoaded &&
    this.state.activationMethodsLoaded &&
    this.state.productBranchesLoaded &&
    //this.state.productBranchesDisplayNamesLoaded &&
    this.state.releasableLoaded &&
    this.state.licenceForMachineNumberLoaded &&
    this.state.lastSeenVersionLoaded &&
    this.state.isDeactivatedLoaded &&
    this.state.isNoteLoaded &&
    this.state.licenceValidUntilLoaded;

  getMethodName = () => {
    if (!this.isActivated()) return "";

    if (this.state.method === 0) return "Online";

    if (this.state.method === 1) return "Offline";

    if (this.state.method === 2) return "Floating";

    return "None";
  };

  getComputerName = () => {
    if (!this.isActivated()) return "";

    return this.state.computerName;
  };

  getActivationMethods = () => {
    let methods = [];

    if (this.state.activationMethods.includes(0)) methods.push("Online");
    if (this.state.activationMethods.includes(1)) methods.push("Offline");
    if (this.state.activationMethods.includes(2)) methods.push("Floating");

    if (methods.length === 0) methods.push("None");

    return methods;
  };

  getStatusChip = () => {
    if (
      this.state.licenceValidUntil != null &&
      moment().isAfter(this.state.licenceValidUntil)
    )
      return (
        <ErrorChip
          size="small"
          label={<Localization>licences.licence_expired</Localization>}
        />
      );

    if (this.state.isDeactivated)
      return (
        <ErrorChip
          size="small"
          label={<Localization>licences.deactivated</Localization>}
        />
      );

    return (
      <SuccessChip
        size="small"
        label={<Localization>licences.activated</Localization>}
      />
    );
  };

  reloadLicence = () => {
    this.setState(this.defaultState());

    this.loadDetails();
    this.loadBranches();
    this.loadFeatures();
    this.loadActivationDate();
    this.loadMethod();
    this.loadComputerName();
    this.loadValidUntil();
  };

  setDeactivated = (deactivated) => {
    LicenceServer.post(
      `api/ManageLicences/${this.props.id}/set-deactivated/${deactivated}`
    ).then((d) => {
      this.reloadLicence();
      const { appDispatch } = this.context;
      appDispatch({
        type: "showMessage",
        data: {
          severity: "success",
          value: "Licence has been successfuly (de)activated.",
        },
      });
    });
  };

  release = () => {
    LicenceServer.post(`api/ManageLicences/${this.props.id}/release`).then(
      (data) => {
        this.reloadLicence();
        const { appDispatch } = this.context;
        appDispatch({
          type: "showMessage",
          data: {
            severity: "success",
            value: "Licence has been successfuly released.",
          },
        });
      }
    );
  };

  activateUniversalLicence = () => {
    LicenceServer.post(
      `api/ManageActivations/${this.props.id}/activate-universal`
    )
      .then((data) => {
        var file = new Blob([JSON.stringify(data, null, 2)], {
          type: "text/plain;charset=utf-8",
        });

        //  responseFile_product_yyyy_mm_dd_hh_mm_ss
        var fileName = `licence_${this.props.id}.json`;

        saveAs(file, fileName);

        const { appDispatch } = this.context;
        appDispatch({
          type: "showMessage",
          data: {
            severity: "success",
            value: "Licence activated and downloaded successfuly",
          },
        });
      })
      .catch((error) => {
        const { appDispatch } = this.context;
        appDispatch({
          type: "showMessage",
          data: {
            severity: "error",
            value: "Licence already activated or activation unsuccessful",
          },
        });
      });
  };

  delete = () => {
    LicenceServer.post(`api/ManageLicences/${this.props.id}/delete`)
      .then((data) => {
        const { appDispatch } = this.context;
        appDispatch({
          type: "showMessage",
          data: {
            severity: "success",
            value: "Licence has been successfuly deleted.",
          },
        });

        this.props.onDelete(this.props.id);
      })
      .catch((error) => {
        const { appDispatch } = this.context;
        appDispatch({
          type: "showMessage",
          data: {
            severity: "error",
            value: "Licence deletion failed.",
          },
        });
      });
  };

  loadFeatures = () => {
    LicenceServer.get(`api/ManageLicences/${this.props.id}/features`).then(
      (data) => this.setState({ featuresLoaded: true, features: data })
    );
  };

  loadActivationDate = () => {
    LicenceServer.get(`api/ManageActivations/${this.props.id}/current/at`)
      .then((data) =>
        this.setState({
          activationDateLoaded: true,
          activationDate: data,
        })
      )
      .catch((error) => {
        this.setState({ activationDateLoaded: true });
      });
  };

  loadMethod = () => {
    //ak je aktivovana
    LicenceServer.get(`api/ManageActivations/${this.props.id}/current/method`)
      .then((data) => {
        this.setState({ methodLoaded: true, method: data });
      })
      .catch((error) => {
        this.setState({ methodLoaded: true });
      });
  };

  loadComputerName = () => {
    LicenceServer.get(
      `api/ManageActivations/${this.props.id}/current/computers`
    )
      .then((data) => {
        this.setState({
          computerNameLoaded: true,
          computerName: data[0].computerName,
        });
      })
      .catch((error) => {
        this.setState({ computerNameLoaded: true });
      });
  };

  loadValidUntil = () => {
    //ak je aktivovana
    LicenceServer.get(
      `api/ManageActivations/${this.props.id}/current/validUntil`
    )
      .then((data) => {
        this.setState({ validUntilLoaded: true, validUntil: data });
      })
      .catch((error) => {
        this.setState({ validUntilLoaded: true, validUntil: null });
      });
  };

  loadDetails = () => {
    LicenceServer.get(`api/ManageLicences/${this.props.id}`).then((data) => {
      this.setState({
        activatedLoaded: true,
        activated: data.activated,
        activationMethodsLoaded: true,
        activationMethods: data.activationMethods,
        releasable: data.releasable,
        releasableLoaded: true,
        licenceForMachineNumber: data.licenceForMachineNumber,
        licenceForMachineNumberLoaded: true,
        isDeactivatedLoaded: true,
        isDeactivated: data.isDeactivated,
        isNoteLoaded: true,
        note: data.note,
        licenceValidUntil: data.licenceValidUntil,
        licenceValidUntilLoaded: true,
        UniversalLicence: data.universalLicence,
      });
    });

    /*TelemetryServer.get(`api/GetLatestVersionForLicenceGUID/${this.props.id}`)
      .then((data) =>
        this.setState({
          lastSeenVersionLoaded: true,
          lastSeenVersion: (
            <>
              <SoftwareVersionString
                versionType={data.versionType}
                versionIteration={data.versionIteration}
                versionNumber={data.versionNumber}
              />
            </>
          ),
        })
      )
      .catch((error) =>
        this.setState({
          lastSeenVersionLoaded: true,
          lastSeenVersion: null,
        })
      );*/

    this.setState({
      lastSeenVersionLoaded: true,
      lastSeenVersion: (
        <>
          <SoftwareVersionString
            versionType={1}
            versionIteration={200}
            versionNumber={200}
          />
        </>
      ),
    });
  };

  loadBranches = () => {
    let allBranches = [];
    VersionInfosServer.get(
      `rest/${this.props.product}/GetProductBranchesDisplayNames`
    ).then((branchData) => {
      for (const branchId of this.props.licenceBranches) {
        const branchDisplayNames = branchData[branchId];
        if (branchDisplayNames) {
          allBranches.push({
            branchNumber: branchId,
            branchNames: branchDisplayNames,
          });
        }
      }

      allBranches.sort((a, b) => {
        return b.branchNumber - a.branchNumber;
      });

      this.setState({
        productBranchesLoaded: true,
        productBranches: allBranches,
      });
    });
  };

  componentDidMount() {
    this.loadDetails();
    this.loadBranches();
    this.loadFeatures();
    this.loadActivationDate();
    this.loadMethod();
    this.loadComputerName();
    this.loadValidUntil();
  }

  render() {
    if (!this.isLoaded()) return <Skeleton variant="rect" />;

    return (
      <CollapsableRow
        cells={[
          this.props.product,
          this.props.displayName,
          this.getComputerName(),
          this.getMethodName(),
          this.getStatusChip(),
        ]}
      >
        <Grid container alignItems="flex-end" justify="space-between">
          <Grid item md={8}>
            {this.state.licenceForMachineNumber != null && (
              <Typography gutterBottom>
                <Chip
                  size="small"
                  color="primary"
                  label={<Localization>licences.machine_licence</Localization>}
                />
              </Typography>
            )}

            <Grid container>
              <Grid item md={8}>
                <Box mb={2}>
                  <Table size="small">
                    <TableBody>
                      {this.state.licenceForMachineNumber != null && (
                        <TableRow>
                          <TableCell>
                            <Localization>licences.machine_number</Localization>
                          </TableCell>
                          <TableCell align="right">
                            {this.state.licenceForMachineNumber}
                          </TableCell>
                        </TableRow>
                      )}
                      {this.state.note != null && (
                        <TableRow>
                          <TableCell>
                            <Localization>licences.note</Localization>
                          </TableCell>
                          <TableCell align="right">{this.state.note}</TableCell>
                        </TableRow>
                      )}
                      {this.state.UniversalLicence && (
                        <TableRow>
                          <TableCell>
                            <Localization>
                              licences.is_universal_licence
                            </Localization>
                          </TableCell>
                          <TableCell align="right">
                            <Localization>util.yes</Localization>
                          </TableCell>
                        </TableRow>
                      )}
                      {this.state.licenceValidUntil != null && (
                        <TableRow>
                          <TableCell>
                            <Localization>
                              licences.limited_validity_until
                            </Localization>
                          </TableCell>
                          <TableCell align="right">
                            <DateTime
                              from={moment(this.state.licenceValidUntil)}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell>
                          <Localization>
                            licences.allowed_activation_methods
                          </Localization>
                        </TableCell>
                        <TableCell align="right">
                          {this.getActivationMethods().reduce((prev, curr) => [
                            prev,
                            "/",
                            curr,
                          ])}
                        </TableCell>
                      </TableRow>
                      {/* vypinam pretoze je to priliz pomale */}
                      {/*<TableRow>
                        <TableCell>
                          <Localization>
                            licences.last_seen_version
                          </Localization>
                        </TableCell>
                        <TableCell align="right">
                          {this.state.lastSeenVersion ?? (
                            <Localization>
                              licences.version_unknown
                            </Localization>
                          )}
                        </TableCell>
                      </TableRow>*/}
                      {this.isActivated() && (
                        <>
                          <TableRow>
                            <TableCell>
                              <Localization>
                                licences.activation_date
                              </Localization>
                            </TableCell>
                            <TableCell align="right">
                              <DateTime from={this.state.activationDate} />
                            </TableCell>
                          </TableRow>
                          {this.state.method === 1 && (
                            <TableRow>
                              <TableCell>
                                <Localization>
                                  licences.prolongation_until
                                </Localization>
                              </TableCell>
                              <TableCell align="right">
                                <DateTime
                                  from={moment(this.state.validUntil)}
                                />
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                      <TableRow>
                        <TableCell>
                          <Localization>licences.allowed_branches</Localization>
                        </TableCell>
                        <TableCell align="right">
                          {this.state.productBranches
                            .map((branch) => branch.branchNames)
                            .flat()
                            .join(" / ")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Localization>licences.releasable</Localization>
                        </TableCell>
                        <TableCell align="right">
                          {this.state.releasable ? (
                            <Localization>util.yes</Localization>
                          ) : (
                            <Localization>util.no</Localization>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            </Grid>

            {this.state.features.length > 0 && (
              <>
                <Localization>licences.features</Localization>
              </>
            )}

            <Typography gutterBottom>
              {this.state.features.map((feature, index) => {
                return (
                  <>
                    <Chip
                      key={index}
                      size="small"
                      color="secondary"
                      label={feature.displayName}
                    />{" "}
                  </>
                );
              })}
            </Typography>
          </Grid>
          <Authorize
            sufficientRole={["LicenceAdmin", "Dealer"]}
            yes={
              <>
                {" "}
                <Grid item>
                  <Grid container justifyContent="flex-end" spacing={2}>
                    <Authorize
                      sufficientRole={["LicenceAdmin"]}
                      yes={
                        <>
                          <Grid item>
                            <EditLicence licenceId={this.props.id} />
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={this.delete}
                              startIcon={<DeleteIcon />}
                              size="small"
                              color="secondary"
                              variant="contained"
                            >
                              <Localization>util.delete</Localization>
                            </Button>
                          </Grid>
                          {this.state.activated && (
                            <Grid item>
                              <Button
                                onClick={this.release}
                                startIcon={<ClearIcon />}
                                size="small"
                                color="primary"
                                variant="contained"
                              >
                                <Localization>licences.release</Localization>
                              </Button>
                            </Grid>
                          )}
                        </>
                      }
                    />

                    <Grid item>
                      {this.state.isDeactivated == false && (
                        <Button
                          onClick={() => {
                            this.setDeactivated(true);
                          }}
                          startIcon={<BlockIcon />}
                          size="small"
                          color="secondary"
                          variant="contained"
                        >
                          <Localization>licences.deactivate</Localization>
                        </Button>
                      )}
                      {this.state.isDeactivated == true && (
                        <Button
                          onClick={() => {
                            this.setDeactivated(false);
                          }}
                          startIcon={<CheckIcon />}
                          size="small"
                          color="secondary"
                          variant="contained"
                        >
                          <Localization>licences.activate</Localization>
                        </Button>
                      )}
                    </Grid>

                    <Authorize
                      sufficientRole={["UniversalLicenceAdmin"]}
                      yes={
                        <>
                          <Grid item>
                            {this.state.UniversalLicence && (
                              <Button
                                onClick={() => {
                                  this.activateUniversalLicence();
                                }}
                                startIcon={<CloudDownloadIcon />}
                                size="small"
                                color="secondary"
                                variant="contained"
                              >
                                <Localization>
                                  manual_activation.download_activation_file
                                </Localization>
                              </Button>
                            )}
                          </Grid>
                        </>
                      }
                    />
                  </Grid>
                </Grid>
              </>
            }
          />
        </Grid>
      </CollapsableRow>
    );
  }
}

Licence.contextType = AppContext;

export default Licence;
