import React, { useState } from "react"
import { Button } from "@material-ui/core"

import PublishIcon from "@material-ui/icons/Publish"
import { useEffect } from "react"
import { Localization } from "../localization/Localization"

function FileUpload(props) {
  const { label, fileContentSetter, accept } = props
  var input

  const [hasFile, setHasFile] = useState(false)
  const [fileName, setFileName] = useState()
  const [file, setFile] = useState()

  const handleClick = e => {
    input.click()
  }

  const handleChange = e => {
    setHasFile(true)

    if (e.target.files.length) {
      var reader = new FileReader()
      reader.readAsText(e.target.files[0], "UTF-8")
      reader.onload = e => {
        setFile(e.target.result)
      }

      setFileName(e.target.files[0].name)
    }
  }

  useEffect(() => {
    if (fileContentSetter && file && fileName) {
      fileContentSetter(file, fileName)
    }
  }, [file])

  return (
    <>
      <input
        id="file-upload"
        type="file"
        hidden={true}
        accept={accept ? accept : ""}
        ref={i => (input = i)}
        onChange={handleChange}
      />

      <Button
        onClick={handleClick}
        color="secondary"
        variant="contained"
        startIcon={<PublishIcon />}
      >
        {hasFile ? (
          <Localization>util.selected</Localization>
        ) : (
          <Localization>util.upload</Localization>
        )}{" "}
        {label}
        {hasFile && `: ${fileName}`}
      </Button>
    </>
  )
}

export default FileUpload
