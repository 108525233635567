import React, { createContext } from "react"
import { useImmerReducer } from "use-immer"
import { UserContextComponent } from "./UserContext"
import { Localizator } from "../localization/Localization"
import { useEffect } from "react"
import UserManagementServer from "../servers/UserManagementServer"

Localizator.init()

const LocalizationContextInitialState = {
  currentLanguage: Localizator.getAvailableLanguages()[0]
}

function LocalizationContextReducer(state, action) {
  switch (action.type) {
    case "setCurrentLanguage":
      state.currentLanguage = action.data

      UserManagementServer.post(
        `api/User/ChangePreferredLanguage/${state.currentLanguage}`
      )
        .then(d => {
          console.log(d)
        })
        .catch(e => {
          console.log(e)
        })

      break
  }
}

const LocalizationContext = createContext()

function LocalizationContextComponent(props) {
  const [localizationState, localizationDispatch] = useImmerReducer(
    LocalizationContextReducer,
    LocalizationContextInitialState
  )

  return (
    <LocalizationContext.Provider
      value={{ localizationState, localizationDispatch }}
    >
      {props.children}
    </LocalizationContext.Provider>
  )
}

export { LocalizationContext, LocalizationContextComponent }
