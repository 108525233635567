import React, { useEffect, useState } from "react";
import { Link, Route } from "react-router-dom";
import { matchPath } from "react-router";
import { withRouter } from "react-router";
import {
  Box,
  MenuList,
  Menu,
  MenuItem,
  Typography,
  ListItemIcon,
  ListSubheader,
  Paper,
  Switch,
} from "@material-ui/core";
import AppsIcon from "@material-ui/icons/Apps";
import { Localization } from "../localization/Localization";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import { Authorize } from "../util/Authorize";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      location: { pathname },
    } = this.props;

    return (
      <Box
        height="100%"
        border={1}
        borderTop={0}
        borderLeft={0}
        borderBottom={0}
        borderColor="grey.300"
      >
        <MenuList>
          <MenuItem
            selected={
              !!matchPath(pathname, { path: "/software", exact: false })
            }
            component={Link}
            to="/software"
          >
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <Localization>navbar.software</Localization>
          </MenuItem>

          <MenuItem
            selected={
              !!matchPath(pathname, {
                path: "/manual-activation",
                exact: false,
              })
            }
            component={Link}
            to="/manual-activation"
          >
            <ListItemIcon>
              <AddCircleOutlineIcon />
            </ListItemIcon>
            <Localization>navbar.manual_activation</Localization>
          </MenuItem>

          <MenuItem
            selected={
              !!matchPath(pathname, { path: "/subscription", exact: false })
            }
            component={Link}
            to="/subscription"
          >
            <ListItemIcon>
              <AutorenewIcon />
            </ListItemIcon>
            <Localization>navbar.subscription</Localization>
          </MenuItem>

          <MenuItem
            selected={!!matchPath(pathname, { path: "/profile", exact: false })}
            component={Link}
            to="/profile"
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <Localization>navbar.profile</Localization>
          </MenuItem>
          <Authorize
            sufficientRole={["UserAdmin", "LicenceAdmin", "Dealer"]}
            yes={
              <>
                <ListSubheader disableSticky>
                  <Localization>navbar.user_management</Localization>
                </ListSubheader>
                <MenuItem component={Link} to="/users">
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <Localization>navbar.users</Localization>
                </MenuItem>
              </>
            }
          />
          <Authorize
            sufficientRole={["LicenceAdmin", "Dealer"]}
            yes={
              <>
                <ListSubheader disableSticky>
                  <Localization>navbar.statistics</Localization>
                </ListSubheader>
                <MenuItem component={Link} to="/exports">
                  <ListItemIcon>
                    <CloudDownloadIcon />
                  </ListItemIcon>
                  <Localization>navbar.exports</Localization>
                </MenuItem>
              </>
            }
          />
          <ListSubheader disableSticky>
            <Localization>navbar.support</Localization>
          </ListSubheader>
          <MenuItem
            component={"a"}
            href="https://www.dropbox.com/s/y8mxty9eaba25tv/CustomerPortal_EN.pdf?dl=0"
            target="_blank"
          >
            <ListItemIcon>
              <HelpOutlineIcon />
            </ListItemIcon>
            <Localization>navbar.help</Localization>
          </MenuItem>
        </MenuList>
      </Box>
    );
  }
}

export default withRouter(Navbar);
