import React, { useEffect, useState } from "react"
import { Typography, Grid, CircularProgress } from "@material-ui/core"
import { useContext } from "react"
import { ManualActivationContext } from "../ManualActivationContext"
import Alert from "@material-ui/lab/Alert"
import LicenceServer from "../../servers/LicenceServer"
import sleep from "../../util/Sleep"
import { Localization } from "../../localization/Localization"

function LicenceBeingActivatedStep({ onNext }) {
  const { manualActivationState, manualActivationDispatch } = useContext(
    ManualActivationContext
  )

  const [activationDone, setActivationDone] = useState(false)
  const [errorDuringActivation, setErrorDuringActivation] = useState()

  useEffect(() => {
    if (
      manualActivationState.requestFileObject &&
      manualActivationState.selectedLicence
    ) {
      setActivationDone(false)

      LicenceServer.post(
        `api/Licences/${manualActivationState.selectedLicence}/activate`,
        manualActivationState.requestFileObject
      )
        .then(data => {
          manualActivationDispatch({ type: "setAnswerFile", data: data })
          setTimeout(() => setActivationDone(true), 5000)
        })
        .catch(error => {
          setActivationDone(false)
          setErrorDuringActivation(error)
        })
    }
  }, [])

  useEffect(() => {
    if (activationDone) {
      onNext()
    }
  }, [activationDone])

  if (errorDuringActivation)
    return (
      <Alert severity="error">
        <Localization>manual_activation.error_during_activation</Localization>
      </Alert>
    )

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <CircularProgress />
        </Grid>
        <Grid item>
          <Typography>
            <Localization>
              manual_activation.activation_in_progress
            </Localization>
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default LicenceBeingActivatedStep
