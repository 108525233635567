import React from "react"

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box
} from "@material-ui/core"

import { withStyles } from "@material-ui/core/styles"

const TableRowNoBorder = withStyles(() => ({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  }
}))(TableRow)

function CollapsableRow(props) {
  const { cells, children } = props
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <TableRowNoBorder>
        {cells.map((cell, index) => (
          <TableCell key={index}>{cell}</TableCell>
        ))}
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRowNoBorder>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box mt={1} mb={2}>
              {children}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default CollapsableRow
