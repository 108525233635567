import MomentUtils from "@date-io/moment";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers/DatePicker";
import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import React, { useEffect, useState, Component } from "react";
import { Localization } from "../../localization/Localization";
import LicenceServer from "../../servers/LicenceServer";
import VersionInfosServer from "../../servers/VersionInfosServer";
import {
  Licence,
  LicenceEdit,
  Product,
  Feature,
  BranchAndPlatformInVersionInfos,
  ActivationMethod,
  Branch,
} from "../../types";
import { Authorize } from "../../util/Authorize";

export interface LicenceFormProps {
  onChange: (licence: Licence) => void;
  initialLicence?: LicenceEdit;
  allowDisplayName: boolean;
  licenceValidityEditable: boolean;
}

type AvailableProduct = {
  product: Product;
  features: Feature[];
  branches: BranchAndPlatformInVersionInfos[];
};

export const LicenceForm = ({
  onChange,
  initialLicence,
  allowDisplayName,
  licenceValidityEditable,
}: LicenceFormProps) => {
  const [licence, setLicence] = useState<LicenceEdit>({
    displayName: initialLicence?.displayName ?? "",
    product: initialLicence?.product ?? "",
    productFeatures: initialLicence?.productFeatures ?? [],
    productFeaturesHasCount: initialLicence?.productFeaturesHasCount ?? [],
    productFeaturesCount: initialLicence?.productFeaturesCount ?? [],
    productBranches: initialLicence?.productBranches ?? [],
    activationMethods: initialLicence?.activationMethods ?? [0],
    licenceForMachineNumber:
      initialLicence?.licenceForMachineNumber ?? undefined,
    note: initialLicence?.note ?? undefined,
    licenceValidUntil: initialLicence?.licenceValidUntil ?? undefined,
    UniversalLicence: initialLicence?.UniversalLicence ?? false,
  });

  const [isLicenceForMachine, setLicenceForMachine] = useState<boolean>(
    initialLicence?.licenceForMachineNumber != undefined
  );
  const [hasNote, setHasNote] = useState<boolean>(
    initialLicence?.note != undefined
  );
  const [hasLimitedValidityUntil, setHasLimitedValidityUntil] =
    useState<boolean>(initialLicence?.licenceValidUntil != undefined);

  const [availableProducts, setAvailableProducts] = useState<
    AvailableProduct[]
  >([]);
  const [availableProductsLoaded, setAvailableProductsLoaded] =
    useState<boolean>(false);
  const [willCheckAll, setWillCheckAll] = useState<boolean>(true);

  useEffect(() => {
    onChange(licence);
  }, [licence]);

  useEffect(() => {
    const allBranches = getDistinctBranchesOfCurrentProduct();
    if (allBranches.length === 1 && licence.productBranches.length === 0) {
      const branch: Branch = allBranches[0].Number;
      setLicence(
        (prevLic) =>
          ({
            ...prevLic,
            productBranches: prevLic.productBranches.concat(branch),
          } as LicenceEdit)
      );
    }
  }, [licence]);

  useEffect(() => {
    // fetch availableProducts
    VersionInfosServer.get(`api/Products`).then(async (products: Product[]) => {
      for (const product of products) {
        const isLicencable = await LicenceServer.get(
          `api/Products/${product}/licencable`
        );
        if (!isLicencable) continue;

        const features: Feature[] = await LicenceServer.get(
          `api/Products/${product}/features`
        );
        const branches: BranchAndPlatformInVersionInfos[] =
          await VersionInfosServer.get(`api/BranchesOfProduct/${product}`);

        setAvailableProducts((prevAvailableProducts) => {
          return prevAvailableProducts.concat({
            product: product,
            features: features,
            branches: branches,
          } as AvailableProduct);
        });
      }

      setAvailableProductsLoaded(true);
    });
  }, []);

  const handleActivationMethodsCheckBox = (event: any): void => {
    const method: ActivationMethod = parseInt(event.target.name);

    if (licence.activationMethods.includes(method) && !event.target.checked) {
      setLicence(
        (prevLic) =>
          ({
            ...prevLic,
            activationMethods: prevLic.activationMethods.filter(
              (m) => m !== method
            ),
          } as LicenceEdit)
      );
    } else if (
      !licence.activationMethods.includes(method) &&
      event.target.checked
    ) {
      setLicence(
        (prevLic) =>
          ({
            ...prevLic,
            activationMethods: prevLic.activationMethods.concat(method),
          } as LicenceEdit)
      );
    }
  };

  const handleProductSelectChange = (event: any): void => {
    const { value } = event.target;

    setLicence(
      (prevLic) =>
        ({
          ...prevLic,
          product: value,
          productFeatures: [],
          productFeaturesHasCount: [],
          productFeaturesCount: [],
          productBranches: [],
        } as LicenceEdit)
    );

    setWillCheckAll(true);
  };

  const handleCheckAllButtonClick = (): void => {
    const allFeaturesNoCountName = getFeaturesOfCurrentProductWithNoCount().map(
      (f) => f.name
    );
    const allFeaturesCountName = getFeaturesOfCurrentProductWithCount().map(
      (f) => f.name
    );

    var newProductFeatures: string[] = [];
    var newProductFeaturesCount: number[] = [];
    var newProductFeaturesHasCount: boolean[] = [];

    if (willCheckAll) {
      newProductFeatures = allFeaturesNoCountName.filter(
        (f) => !licence.productFeatures.includes(f)
      );

      newProductFeaturesCount = newProductFeatures
        .map(() => 0)
        .concat(licence.productFeaturesCount);
      newProductFeaturesHasCount = newProductFeatures
        .map(() => false)
        .concat(licence.productFeaturesHasCount);
      newProductFeatures = newProductFeatures.concat(licence.productFeatures);
    } else {
      newProductFeatures = licence.productFeatures.filter(
        (f) => !allFeaturesNoCountName.includes(f)
      );

      licence.productFeaturesCount.forEach(function (val, idx) {
        if (allFeaturesCountName.includes(licence.productFeatures[idx]))
          newProductFeaturesCount.push(val);
      });

      newProductFeaturesHasCount = newProductFeatures.map(() => true);
    }

    setLicence(
      (prevLic) =>
        ({
          ...prevLic,

          productFeatures: newProductFeatures,

          productFeaturesHasCount: newProductFeaturesHasCount,

          productFeaturesCount: newProductFeaturesCount,
        } as LicenceEdit)
    );

    setWillCheckAll(!willCheckAll);
  };

  const handleProductFeatureCheckBox = (event: any): void => {
    const feature: string = event.target.name;

    if (licence.productFeatures.includes(feature) && !event.target.checked) {
      // index featury
      const index: number = licence.productFeatures.indexOf(feature);
      const newProductFeatures: string[] = licence.productFeatures;
      const newProductFeaturesHasCount: boolean[] =
        licence.productFeaturesHasCount;
      const newProductFeaturesCount: number[] = licence.productFeaturesCount;
      newProductFeatures.splice(index, 1);
      newProductFeaturesHasCount.splice(index, 1);
      newProductFeaturesCount.splice(index, 1);

      setLicence(
        (prevLic) =>
          ({
            ...prevLic,
            productFeatures: newProductFeatures,
            productFeaturesHasCount: newProductFeaturesHasCount,
            productFeaturesCount: newProductFeaturesCount,
          } as LicenceEdit)
      );
    } else if (
      !licence.productFeatures.includes(feature) &&
      event.target.checked
    ) {
      const hasCount: boolean = getFeaturesOfCurrentProductWithCount().some(
        (f: Feature) => f.name === feature
      );

      setLicence(
        (prevLic) =>
          ({
            ...prevLic,
            productFeatures: prevLic.productFeatures.concat(feature),
            productFeaturesHasCount:
              prevLic.productFeaturesHasCount.concat(hasCount),
            productFeaturesCount: prevLic.productFeaturesCount.concat(0),
          } as LicenceEdit)
      );
    }
  };

  const handleProductBranchCheckBox = (event: any): void => {
    const branch: Branch = parseInt(event.target.name);

    if (licence.productBranches.includes(branch) && !event.target.checked) {
      setLicence(
        (prevLic) =>
          ({
            ...prevLic,
            productBranches: prevLic.productBranches.filter(
              (m) => m !== branch
            ),
          } as LicenceEdit)
      );
    } else if (
      !licence.productBranches.includes(branch) &&
      event.target.checked
    ) {
      setLicence(
        (prevLic) =>
          ({
            ...prevLic,
            productBranches: prevLic.productBranches.concat(branch),
          } as LicenceEdit)
      );
    }
  };

  const handleLicenceForMachineCheckBox = (event: any) => {
    var checked: boolean = event.target.checked;

    setLicenceForMachine(checked);
    setLicence(
      (prevLic) =>
        ({
          ...prevLic,
          haveLicenceForMachine: checked,
          licenceForMachineNumber: undefined,
        } as LicenceEdit)
    );
  };

  const handleNoteCheckBox = (event: any) => {
    var checked: boolean = event.target.checked;

    setHasNote(checked);
    setLicence(
      (prevLic) =>
        ({
          ...prevLic,
          note: undefined,
        } as LicenceEdit)
    );
  };

  const handleLimitedValidityUntilCheckBox = (event: any) => {
    var checked: boolean = event.target.checked;

    setHasLimitedValidityUntil(checked);
    setLicence(
      (prevLic) =>
        ({
          ...prevLic,
          licenceValidUntil: checked ? moment().toISOString() : undefined,
        } as LicenceEdit)
    );
  };

  const handleUniversalLicenceCheckBox = (event: any) => {
    var checked: boolean = event.target.checked;

    setLicence(
      (prevLic) =>
        ({
          ...prevLic,
          UniversalLicence: checked,
        } as LicenceEdit)
    );
  };

  const getFeaturesOfCurrentProduct = (): Feature[] => {
    if (!licence.product) return [];

    return availableProducts.filter(
      ({ product }) => product == licence.product
    )[0].features;
  };

  const getFeaturesOfCurrentProductWithNoCount = (): Feature[] => {
    return getFeaturesOfCurrentProduct().filter((f) => !f.hasCount);
  };

  const getFeaturesOfCurrentProductWithCount = (): Feature[] => {
    return getFeaturesOfCurrentProduct().filter((f) => f.hasCount);
  };

  const getBranchesOfCurrentProduct = (): BranchAndPlatformInVersionInfos[] => {
    if (!licence.product) return [];

    const filtered = availableProducts.filter(
      ({ product }) => product == licence.product
    );

    if (filtered.length > 0) return filtered[0].branches;
    else return [];
  };

  const getDistinctBranchesOfCurrentProduct =
    (): BranchAndPlatformInVersionInfos[] => {
      if (!licence.product) return [];

      const bs = getBranchesOfCurrentProduct().map((b) => b.Number);

      const distinctBranchNumbers: number[] = Array.from(
        new Set(getBranchesOfCurrentProduct().map((b) => b.Number))
      );

      let distinctBranches: BranchAndPlatformInVersionInfos[] = [];

      distinctBranchNumbers.forEach((number) => {
        distinctBranches.push(
          getBranchesOfCurrentProduct().find(
            (b) => b.Number == number
          ) as BranchAndPlatformInVersionInfos
        );
      });

      return distinctBranches;
    };

  const hasCurrentProductAnyFeatureWithNoCount = (): boolean => {
    return getFeaturesOfCurrentProductWithNoCount().length > 0;
  };

  const hasCurrentProductAnyFeatureWithCount = (): boolean => {
    return getFeaturesOfCurrentProductWithCount().length > 0;
  };

  const hasCurrentProductAnyBranch = (): boolean => {
    return getBranchesOfCurrentProduct().length > 0;
  };

  const getCountValueOfFeature = (feature: Feature): number => {
    const index: number = licence.productFeatures.indexOf(feature.name);
    return licence.productFeaturesCount[index];
  };

  const updateCountValueOfFeature = (feature: Feature, newValue: number) => {
    const index: number = licence.productFeatures.indexOf(feature.name);
    const newProductFeaturesCount: number[] = licence.productFeaturesCount;
    newProductFeaturesCount[index] = newValue;
    setLicence(
      (prevLic) =>
        ({
          ...prevLic,
          productFeaturesCount: newProductFeaturesCount,
        } as LicenceEdit)
    );
  };

  return (
    <>
      {allowDisplayName && (
        <TextField
          variant="outlined"
          autoFocus
          margin="dense"
          id="displayName"
          label={<Localization>users.licence_displayname</Localization>}
          type="text"
          fullWidth
          autoComplete="off"
          value={licence.displayName}
          onChange={(e) => {
            const { value } = e.target;
            setLicence(
              (prevLic) => ({ ...prevLic, displayName: value } as LicenceEdit)
            );
          }}
        />
      )}

      <Box mt={2} mb={2}>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel>
            <Localization>licences.product</Localization>
          </InputLabel>
          <Select
            value={licence.product}
            onChange={handleProductSelectChange}
            label={<Localization>licences.product</Localization>}
          >
            {availableProducts.map(
              ({ product, features }: AvailableProduct, index: number) => (
                <MenuItem key={index} value={product}>
                  {product}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Box>

      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography>
            <Localization>licences.activation_methods</Localization>:
          </Typography>
        </Grid>
        <Grid item>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name={"0"}
                  defaultChecked={licence.activationMethods.includes(0)}
                  onChange={handleActivationMethodsCheckBox}
                />
              }
              label={<Localization>licences.online</Localization>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name={"1"}
                  defaultChecked={licence.activationMethods.includes(1)}
                  onChange={handleActivationMethodsCheckBox}
                />
              }
              label={<Localization>licences.offline</Localization>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name={"2"}
                  defaultChecked={licence.activationMethods.includes(2)}
                  onChange={handleActivationMethodsCheckBox}
                />
              }
              label={<Localization>licences.floating</Localization>}
            />
          </FormGroup>
        </Grid>
      </Grid>

      {/* Jednoznacnost releasovatelnosti je dana tym ci je licencia na stroj alebo nie */}
      {/*<Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography>Releasable:</Typography>
        </Grid>
        <Grid item>
          <Checkbox
            color="primary"
            name={"0"}
            checked={licence.releasable}
            onChange={handleReleasableCheckBox}
          />
        </Grid>
      </Grid>*/}

      {availableProductsLoaded && hasCurrentProductAnyFeatureWithNoCount() && (
        <Grid container alignItems="center" justify="space-between" spacing={2}>
          <Grid item>
            <Typography>
              <Localization>licences.features</Localization>:
            </Typography>
          </Grid>
          <Grid item>
            <FormGroup row>
              <Button
                size="small"
                onClick={handleCheckAllButtonClick}
                style={{ alignSelf: "flex-end" }}
              >
                {willCheckAll ? (
                  <Typography>
                    <Localization>licences.licence_checkall</Localization>
                  </Typography>
                ) : (
                  <Typography>
                    <Localization>licences.licence_uncheckall</Localization>
                  </Typography>
                )}
              </Button>
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              {licence.product &&
                getFeaturesOfCurrentProductWithNoCount().map((feature) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={feature.name}
                        checked={licence.productFeatures.includes(feature.name)}
                        onChange={handleProductFeatureCheckBox}
                      />
                    }
                    label={feature.displayName}
                  />
                ))}
            </FormGroup>
          </Grid>
          {availableProductsLoaded &&
            hasCurrentProductAnyFeatureWithCount() && (
              <Grid item>
                {licence.product &&
                  getFeaturesOfCurrentProductWithCount().map((feature) => (
                    <Grid container>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name={feature.name}
                              defaultChecked={licence.productFeatures.includes(
                                feature.name
                              )}
                              onChange={handleProductFeatureCheckBox}
                            />
                          }
                          label={feature.displayName}
                        />
                      </Grid>
                      {licence.productFeatures.includes(feature.name) && (
                        <Grid item>
                          <TextField
                            id="outlined-basic"
                            label={<Localization>users.value</Localization>}
                            variant="outlined"
                            size="small"
                            autoComplete="off"
                            fullWidth
                            defaultValue={getCountValueOfFeature(feature)}
                            onChange={(e) => {
                              const { value } = e.target;
                              updateCountValueOfFeature(
                                feature,
                                parseInt(value)
                              );
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  ))}
              </Grid>
            )}
        </Grid>
      )}

      {availableProductsLoaded &&
        hasCurrentProductAnyBranch() &&
        getDistinctBranchesOfCurrentProduct().length > 1 && (
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Typography>
                <Localization>licences.branches</Localization>:
              </Typography>
            </Grid>
            <Grid item>
              <FormGroup row>
                {licence.product &&
                  getDistinctBranchesOfCurrentProduct().map((branch, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          color="primary"
                          name={`${branch.Number}`}
                          defaultChecked={licence.productBranches.includes(
                            branch.Number
                          )}
                          onChange={handleProductBranchCheckBox}
                        />
                      }
                      label={branch.DisplayName}
                    />
                  ))}
              </FormGroup>
            </Grid>
          </Grid>
        )}

      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name={"licenceForMachine"}
                  defaultChecked={isLicenceForMachine}
                  onChange={handleLicenceForMachineCheckBox}
                />
              }
              label={<Localization>users.licence_for_machine</Localization>}
            />
          </FormGroup>
        </Grid>
        <Grid item>
          {isLicenceForMachine && (
            <TextField
              error={licence.licenceForMachineNumber == undefined}
              helperText={
                licence.licenceForMachineNumber == undefined ? (
                  <Localization>users.four_digit_number</Localization>
                ) : (
                  ""
                )
              }
              id="outlined-basic"
              label={<Localization>licences.machine_number</Localization>}
              variant="outlined"
              size="small"
              autoComplete="off"
              value={licence.licenceForMachineNumber}
              onChange={(e) => {
                const { value } = e.target;
                const valid: boolean =
                  value.match(/^[0-9][0-9][0-9][0-9]$/g) != null;

                setLicence(
                  (prevLic) =>
                    ({
                      ...prevLic,
                      licenceForMachineNumber: valid
                        ? parseInt(value)
                        : undefined,
                    } as LicenceEdit)
                );
              }}
            />
          )}
        </Grid>
      </Grid>

      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name={"note"}
                  defaultChecked={hasNote}
                  onChange={handleNoteCheckBox}
                />
              }
              label={<Localization>users.licence_note</Localization>}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={9}>
          {hasNote && (
            <TextField
              id="outlined-basic"
              label={<Localization>users.licence_note</Localization>}
              variant="outlined"
              size="small"
              autoComplete="off"
              fullWidth
              value={licence.note}
              onChange={(e) => {
                const { value } = e.target;

                setLicence(
                  (prevLic) =>
                    ({
                      ...prevLic,
                      note: value,
                    } as LicenceEdit)
                );
              }}
            />
          )}
        </Grid>

        <Authorize
          sufficientRole={["UniversalLicenceAdmin"]}
          yes={
            (
              <Grid item xs={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={"UniversalLicence"}
                        checked={licence.UniversalLicence}
                        onChange={handleUniversalLicenceCheckBox}
                      />
                    }
                    label={
                      <Localization>licences.is_universal_licence</Localization>
                    }
                  />
                </FormGroup>
              </Grid>
            ) as any
          }
          no={(<></>) as any}
        />

        {licenceValidityEditable && (
          <Grid item xs={12}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name={"limitedValidityUntil"}
                    defaultChecked={hasLimitedValidityUntil}
                    onChange={handleLimitedValidityUntilCheckBox}
                  />
                }
                label={
                  <Localization>licences.limited_validity_until</Localization>
                }
              />
            </FormGroup>

            {hasLimitedValidityUntil && (
              <>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    //variant="inline"
                    format="DD.MM.yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date picker inline"
                    //value={this.state.validFrom}
                    //onChange={(date) => this.setState({ validFrom: date })}
                    fullWidth
                    variant="static"
                    onChange={(
                      d: MaterialUiPickersDate,
                      date?: string | null | undefined
                    ) => {
                      setLicence(
                        (prevLic) =>
                          ({
                            ...prevLic,
                            licenceValidUntil: d?.toISOString(),
                          } as LicenceEdit)
                      );
                    }}
                    value={licence.licenceValidUntil}
                  />
                </MuiPickersUtilsProvider>
              </>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};
