import { createMuiTheme } from "@material-ui/core/styles"
import createPalette from "@material-ui/core/styles/createPalette"

const Theme = createMuiTheme({
  palette: {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#fff", default: "#fafafa" },
    primary: {
      light: "rgba(0, 85, 172, 1)",
      main: "rgba(0, 57, 115, 1)",
      dark: "rgba(0, 39, 81, 1)",
      contrastText: "#fff"
    },
    secondary: {
      light: "rgba(252, 218, 97, 1)",
      main: "rgba(253, 204, 23, 1)",
      dark: "rgba(219, 172, 0, 1)",
      contrastText: "rgba(24, 19, 4, 1)"
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff"
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)"
    }
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        "&:before": {
          display: "none"
        }
      }
    }
  }
})

export default Theme
