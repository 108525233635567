import React, { useEffect } from "react"
import FileUpload from "../util/FileUpload"
import { Button, Grid, Typography, Paper } from "@material-ui/core"
import ManualActivationStepper from "./ManualActivationStepper"
import { Localization } from "../localization/Localization"

function ManualActivationScreen() {
  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Typography variant="h4" color="primary">
          <Localization>manual_activation.title</Localization>
        </Typography>
        <Typography variant="body1">
          <Localization>manual_activation.guidance</Localization>
        </Typography>
      </Grid>

      <Grid md={12}>
        <ManualActivationStepper />
      </Grid>
    </Grid>
  )
}

export default ManualActivationScreen
