import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import VersionInfosServer from "../../servers/VersionInfosServer";
import LicenceServer from "../../servers/LicenceServer";
import { AppContext } from "../../context/AppContext";
import UserManagementServer from "../../servers/UserManagementServer";
import { LicenceForm } from "./LicenceForm";
import { Localization } from "../../localization/Localization";
import Licence from "../../types.d.ts";

class CreateLicenceForUserDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClose = () => {
    this.props.close();
  };

  handleSubmit = async () => {
    /*if (this.state.licenceName === "") {
      this.context.appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: "Licence must have a name."
        }
      })
      return
    }*/

    if (this.state.product === null) {
      this.context.appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: "Product must be selected.",
        },
      });
      return;
    }

    if (this.state.productBranches.length == 0) {
      this.context.appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: "At least one branch must be selected.",
        },
      });
      return;
    }

    if (
      this.state.UniversalLicence &&
      this.state.licenceValidUntil == undefined
    ) {
      this.context.appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: "Universal licence must have validity defined.",
        },
      });
      return;
    }

    if (this.state.activationMethods.length === 0) {
      this.context.appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: "At least one activation method must be selected.",
        },
      });
      return;
    }

    if (
      this.state.haveLicenceForMachine &&
      this.state.licenceForMachineNumber == undefined
    ) {
      this.context.appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: "The machine number should be specified.",
        },
      });
      return;
    }

    //first check is looking if we have floating license selected as activation method. For context look: ActivationMethod in types.d.tsx
    if (
      this.state.activationMethods.includes(2) &&
      this.state.haveLicenceForMachine
    ) {
      this.context.appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value:
            "Floating and licence for machine can't be seclected together.",
        },
      });
      return;
    }

    LicenceServer.post(
      `api/ManageLicences/create/${this.props.user}`,
      Object.assign(this.state, Licence)
    )
      .then((data) => {
        this.context.appDispatch({
          type: "showMessage",
          data: {
            severity: "success",
            value: "Licence created successfuly.",
          },
        });
        this.props.close();
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <>
        <Dialog open={this.props.open} onClose={this.handleClose}>
          <DialogTitle>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Localization>users.add_licence</Localization>
              </Grid>
              <Grid item>
                <IconButton onClick={this.handleClose} color="primary">
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <LicenceForm
              onChange={(licence) => this.setState(licence)}
              allowDisplayName={false}
              licenceValidityEditable={true}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleSubmit}
              color="primary"
              variant="contained"
              startIcon={<AddCircleOutlineIcon />}
            >
              <Localization>util.add</Localization>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

CreateLicenceForUserDialog.contextType = AppContext;

export default CreateLicenceForUserDialog;
