import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import LicenceServer from "../servers/LicenceServer";
import UserManagementServer from "../servers/UserManagementServer";
import { AppContext } from "../context/AppContext";
import { withRouter } from "react-router-dom";
import { Localization } from "../localization/Localization";

class DeleteUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationDialogOpen: false,
    };
  }

  openConfirmationDialog = () => {
    this.setState({ confirmationDialogOpen: true });
  };

  closeConfirmationDialog = () => {
    this.setState({ confirmationDialogOpen: false });
  };

  handleDelete = () => {
    LicenceServer.post(`api/ManageUsers/${this.props.user}/delete`)
      .then(() => {
        UserManagementServer.post(`api/ManageUsers/${this.props.user}/Delete`)
          .then(() => {
            this.context.appDispatch({
              type: "showMessage",
              data: {
                severity: "success",
                value: "User deleted successfuly.",
              },
            });
            this.props.history.push("/users");
          })
          .catch((error) => {
            this.context.appDispatch({
              type: "showMessage",
              data: {
                severity: "error",
                value: "User deletion on user server side failed.",
              },
            });
          });
      })
      .catch((error) => {
        this.context.appDispatch({
          type: "showMessage",
          data: {
            severity: "error",
            value: "User deletion on licence server side failed.",
          },
        });
      });
  };

  render() {
    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          onClick={this.openConfirmationDialog}
          startIcon={<DeleteIcon />}
        >
          <Localization>users.delete_user</Localization>
        </Button>
        <Dialog
          open={this.state.confirmationDialogOpen}
          onClose={this.closeConfirmationDialog}
        >
          <DialogTitle>
            <Localization>users.are_you_sure_delete_title</Localization>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Localization>users.are_you_sure_delete_text</Localization>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{
              justifyContent: "space-between",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<DeleteIcon />}
              onClick={this.handleDelete}
            >
              <Localization>users.delete_anyway</Localization>
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.closeConfirmationDialog}
            >
              <Localization>util.close</Localization>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

DeleteUser.contextType = AppContext;

export default withRouter(DeleteUser);
