import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Card,
  CardContent,
  Typography,
  Grid,
  CardActionArea,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Box,
  Button,
} from "@material-ui/core";

import MenuBookIcon from "@material-ui/icons/MenuBook";
import { SoftwareContext } from "../../context/SoftwareContext";
import VersionInfosServer from "../../servers/VersionInfosServer";
import { Skeleton } from "@material-ui/lab";
import { Alert } from "@material-ui/lab";
import { Localization } from "../../localization/Localization";

type Documentation = {
  ProductId: string;
  DownloadUrl: string;
  DisplayName: string;
  Language: string;
};

type Tutorial = {
  DisplayName: string;
  Url: string;
  Language: string;
  Level: number;
};

const Documentations = () => {
  const { product } = useContext(SoftwareContext).softwareState;
  const [docs, setDocs] = useState<Documentation[]>([]);
  const [tutorials, setTutorials] = useState<Tutorial[]>([]);
  const [docsLoaded, setDocsLoaded] = useState(false);
  const [tutorialsLoaded, setTutorialsLoaded] = useState(false);

  useEffect(() => {
    VersionInfosServer.get(`rest/${product}/Documentation`).then((data) => {
      setDocs(data);
      setDocsLoaded(true);
    });

    VersionInfosServer.get(`rest/${product}/Tutorial`).then((data) => {
      setTutorials(data);
      setTutorialsLoaded(true);
    });
  }, [product]);

  const levelToLocalization = (level: number) => {
    switch (level) {
      case 0:
        return <Localization>tutorials.level_novice</Localization>;
      case 1:
        return <Localization>tutorials.level_advanced</Localization>;
      case 2:
        return <Localization>tutorials.level_expert</Localization>;
      default:
        return level;
    }
  };

  return (
    <>
      <Typography variant="h4" color="primary" gutterBottom>
        <Localization>documentation.title</Localization>
      </Typography>
      <Grid container spacing={1}>
        {docsLoaded &&
          docs.map((doc, index) => (
            <Grid item key={index}>
              <Card elevation={5}>
                <CardActionArea href={doc.DownloadUrl}>
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Grid item>
                        <MenuBookIcon />
                      </Grid>
                      <Grid item>
                        <Typography variant="h5">{doc.DisplayName}</Typography>
                      </Grid>
                    </Grid>
                    <Typography variant="button" color="textSecondary">
                      {doc.Language}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}

        {docsLoaded && docs.length == 0 && (
          <Grid item md={12}>
            <Alert severity="info">
              <Localization>documentation.no_docs</Localization>
            </Alert>
          </Grid>
        )}

        {!docsLoaded && (
          <Grid item md={4}>
            <Card elevation={5}>
              <CardActionArea>
                <CardContent>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <MenuBookIcon />
                    </Grid>
                    <Grid item md={10}>
                      <Typography variant="h5">
                        <Skeleton />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography variant="button" color="textSecondary">
                    <Skeleton />
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        )}
      </Grid>

      {tutorialsLoaded && tutorials.length > 0 && (
        <Box mt={4}>
          <Typography variant="h4" color="primary" gutterBottom>
            <Localization>tutorials.title</Localization>
          </Typography>
          <TableContainer elevation={4} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Localization>tutorials.topic</Localization>
                  </TableCell>
                  <TableCell>
                    <Localization>tutorials.language</Localization>
                  </TableCell>
                  <TableCell>
                    <Localization>tutorials.level</Localization>
                  </TableCell>
                  <TableCell align="right">
                    <Localization>tutorials.link</Localization>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tutorials.map((tutorial: Tutorial) => (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {tutorial.DisplayName}
                    </TableCell>
                    <TableCell>{tutorial.Language}</TableCell>
                    <TableCell>{levelToLocalization(tutorial.Level)}</TableCell>
                    <TableCell align="right">
                      <Button
                        href={tutorial.Url}
                        target="_blank"
                        size="small"
                        variant="outlined"
                        color="primary"
                      >
                        <Localization>tutorials.watch_tutorial</Localization>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default Documentations;
