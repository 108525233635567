/* eslint import/no-webpack-loader-syntax: off */

import React from "react";
import ini from "ini";

import english from "!!raw-loader!../assets/localization/English/customer-portal.ini";
import slovenčina from "!!raw-loader!../assets/localization/Slovak/customer-portal.ini";
import deutsch from "!!raw-loader!../assets/localization/German/customer-portal.ini";
import čeština from "!!raw-loader!../assets/localization/Czech/customer-portal.ini";
import italiano from "!!raw-loader!../assets/localization/Italian/customer-portal.ini";
import français from "!!raw-loader!../assets/localization/French/customer-portal.ini";
import español from "!!raw-loader!../assets/localization/Spanish/customer-portal.ini";
import svenska from "!!raw-loader!../assets/localization/Swedish/customer-portal.ini";

import { LocalizationContext } from "../context/LocalizationContext";

var languagesRaw = [
  english,
  slovenčina,
  deutsch,
  čeština,
  italiano,
  français,
  español,
  svenska,
];
var languagesObj = [];

var currentLanguage = "";

class Localizator {
  static init() {
    for (let language of languagesRaw) {
      languagesObj.push(ini.parse(language));
    }
    currentLanguage = languagesObj[0];
  }

  static getAvailableLanguages() {
    return languagesObj.map((lang) => lang.language);
  }

  static translate(langName, key) {
    var translation = this.fetchFromObject(
      languagesObj.find((lang) => lang.language === langName),
      key
    );

    if (typeof translation === "undefined" || translation === false) {
      return this.translate("English", key);
    }

    return translation;
  }

  // "prop1.prop2.prop3" => obj["prop1"]["prop2"]["prop3"]
  static fetchFromObject(obj, prop) {
    if (typeof obj === "undefined") {
      return false;
    }

    var _index = prop.indexOf(".");
    if (_index > -1) {
      return this.fetchFromObject(
        obj[prop.substring(0, _index)],
        prop.substr(_index + 1)
      );
    }

    return obj[prop];
  }
}

class Localization extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <LocalizationContext.Consumer>
        {(value) =>
          Localizator.translate(
            value.localizationState.currentLanguage,
            this.props.children
          )
        }
      </LocalizationContext.Consumer>
    );
  }
}

export { Localization, Localizator };
