import React from "react"

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Chip,
  TableCell,
  Radio,
  Typography
} from "@material-ui/core"
import Axios from "axios"
import { Skeleton } from "@material-ui/lab"
import LicenceServer from "../../servers/LicenceServer"
import { Localization } from "../../localization/Localization"

class FreeLicenceSelector extends React.Component {
  constructor(props) {
    super(props)
    this.product = props.product
    this.requestedComputers = props.requestedComputers
    this.state = {
      selectedLicence: null,
      licencesLoaded: false,
      reactivatableLicences: [],
      licences: []
    }
  }

  areComputersDifferent(computer1, computer2) {
    let identifiers1 = computer1.identifiers;
    let identifiers2 = computer2.identifiers;

	//  aspon 3 rovnake identifier-y
    var correctInfoCount = 0;

    for(let key in identifiers1)
    {
      if(key in identifiers2)
        if(identifiers1[key] === identifiers2[key])
          correctInfoCount++;
    }

    //  true = su rozne, false = su rovnake
    return correctInfoCount < 3;
  }

  componentDidMount() {
    LicenceServer.get(`api/licences/product/${this.product}/free/offline`).then(
      data => {
        this.setState({ licences: data })
      }
    )

    var reactivatableLicencesTemp = [];

    LicenceServer.get(`api/licences/product/${this.product}/`).then(
      data => {
        const filtered = data.filter(licence => licence.activationMethods.includes(1))
                            .filter(licence => licence.activated);

        const promises = filtered.map(lic => {
          return LicenceServer.get(`api/Activations/${lic.licenceId}/current/computers`).then(
            computers => {
              // pokial mame viac nez jeden requestedComputers/zaregistrovany computer-y pre licenciu
              var matchingComputers = computers.filter(computer1 => 
                this.requestedComputers.every(computer2 => !this.areComputersDifferent(computer1, computer2))
              );

              if (matchingComputers.length > 0) {
                reactivatableLicencesTemp.push(lic);
              }
            }
          );
        });

        return Promise.all(promises).then(() => {
          this.setState(prevState => ({
            licencesLoaded: true, 
            reactivatableLicences: [...prevState.reactivatableLicences, ...reactivatableLicencesTemp],
            licences: [...prevState.licences, ...reactivatableLicencesTemp]
          }));
        });
      }
    );
  }

  handleChange = event => {
    this.setState({ selectedLicence: event.target.value })
    this.props.selectedLicenceSetter(event.target.value)
  }

  render() {
    if (!this.state.licencesLoaded)
      return <Skeleton variant="rect" height={40} />

    return (
      <>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Localization>licences.licence_name</Localization>
                </TableCell>
                <TableCell align="right">
                  <Localization>licences.features</Localization>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.licences.map(licence => {
                return (
                  <TableRow key={licence.licenceId}>
                    <TableCell>
                      <Radio
                        checked={
                          this.state.selectedLicence === licence.licenceId
                        }
                        onChange={this.handleChange}
                        value={licence.licenceId}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>{licence.displayName}</TableCell>

                    <TableCell align="right">
                      { this.state.reactivatableLicences.includes(licence) && (
                        <>
                          <Chip
                            size="small"
                            color="primary"
                            label={<Localization>manual_activation.reactivation</Localization>}
                          />{" "}
                        </>
                      )}
                      {licence.productFeatures.map((feature, index) => {
                        return (
                          <>
                            <Chip
                              key={index}
                              size="small"
                              color="secondary"
                              label={feature}
                            />{" "}
                          </>
                        )
                      })}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }
}

export default FreeLicenceSelector
