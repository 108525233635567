import React, { createContext } from "react"
import { useImmerReducer } from "use-immer"
import { UserContextComponent } from "./UserContext"
import { LocalizationContextComponent } from "./LocalizationContext"

const AppContextInitialState = {
  messages: []
}

function AppContextReducer(state, action) {
  switch (action.type) {
    case "showMessage":
      state.messages.push(action.data)
      break
  }
}

const AppContext = createContext()

function AppContextComponent(props) {
  const [appState, appDispatch] = useImmerReducer(
    AppContextReducer,
    AppContextInitialState
  )

  return (
    <AppContext.Provider value={{ appState, appDispatch }}>
      <LocalizationContextComponent>
        <UserContextComponent>{props.children}</UserContextComponent>
      </LocalizationContextComponent>
    </AppContext.Provider>
  )
}

export { AppContext, AppContextComponent }
