import React, { useEffect, useState } from "react"

import { Box, Grid, Paper } from "@material-ui/core"

import Header from "./Header"
import Navbar from "./Navbar"
import Footer from "./Footer"
import Content from "./Content"

function Portal() {
  return (
    <>
      <Header />

      <Paper>
        <Grid container>
          <Grid item>
            <Navbar />
          </Grid>
          <Box flexGrow={1} flexBasis={0}>
            <Content />
          </Box>
        </Grid>
      </Paper>

      <Footer />
    </>
  )
}

export default Portal
