import { Box, Button, Grid, Typography } from "@material-ui/core"
import React from "react"
import { Link } from "react-router-dom"
import { Localization } from "../localization/Localization"
import SubscriptionStatusBox from "./SubscriptionStatusBox"

function SubscriptionScreen() {
  return (
    <>
      <Typography variant="h4" color="primary" gutterBottom>
        <Localization>subscription.title</Localization>
      </Typography>
      <Grid container>
        <Grid item>
          <SubscriptionStatusBox />
          <Box mt={2}>
            <Typography color="primary" variant="body1">
              <Localization>subscription.interested</Localization>{" "}
              <a target="_blank" href="https://www.microstep.eu/dealers/">
                <Localization>subscription.partner</Localization>
              </a>
              {"."}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default SubscriptionScreen
