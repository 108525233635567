import React from "react";

import { Chip } from "@material-ui/core";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";

const SuccessChip = withStyles(
  (theme) => ({
    root: {
      background: theme.palette.success.main,
    },
    label: {
      color: "#fff",
    },
    icon: {
      color: "#fff",
    },
  }),
  { withTheme: true }
)(Chip);

const ErrorChip = withStyles(
  (theme) => ({
    root: {
      background: theme.palette.error.main,
    },
    label: {
      color: "#fff",
    },
    icon: {
      color: "#fff",
    },
  }),
  { withTheme: true }
)(Chip);

const MachineNumberChip = withStyles(
  (theme) => ({
    root: {
      margin: theme.spacing(0.3),
    },
  }),
  { withTheme: true }
)(Chip);

const ActivatedChip = (props) => {
  return <SuccessChip label={props.label} icon={<CheckIcon />} />;
};

const NotActivatedChip = (props) => {
  return <ErrorChip label={props.label} icon={<CloseIcon />} />;
};

export {
  SuccessChip,
  ErrorChip,
  MachineNumberChip,
  ActivatedChip,
  NotActivatedChip,
};
