import Axios from "axios";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import sleep from "../util/Sleep";

var url = "https://licence.microstep.sk/";

class LicenceServer {
  static async get(route, body = {}) {
    try {
      const res = await Axios.get(url + route, body);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
  static async post(route, body = {}) {
    const res = await Axios.post(url + route, body);
    return res.data;
  }
  static async patch(route, body = {}) {
    const res = await Axios.patch(url + route, body);
    return res.data;
  }
}

export default LicenceServer;
