import React, { useEffect } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper
} from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import UserManagementServer from "../servers/UserManagementServer"
import { Link } from "react-router-dom"
import LicenceServer from "../servers/LicenceServer"
import { Localization } from "../localization/Localization"

class UserLicencesTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      licences: [],
      licencesLoaded: false
    }
  }

  componentDidMount() {
    LicenceServer.get(`api/LicencesManagement/of/${this.props.user}`).then(
      data => {
        this.setState({ licences: data, licencesLoaded: true })
        console.log(data)
      }
    )
  }

  render() {
    if (this.state.licencesLoaded == false) return <Skeleton></Skeleton>

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Localization>licences.licence_name</Localization>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.licences.map(licence => (
              // <TableRow key={user.userName}>
              //   <TableCell>{user.userName}</TableCell>
              //   <TableCell>{user.fullName}</TableCell>
              //   <TableCell>{user.email}</TableCell>
              //   <TableCell align="right">
              //     <Button
              //       color="secondary"
              //       variant="contained"
              //       component={Link}
              //       to={`users/${user.userName}`}
              //     >
              //       Manage
              //     </Button>
              //   </TableCell>
              // </TableRow>
              <></>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

export default UserLicencesTable
