import React, { useEffect, useState } from "react"
import { Box, Typography, Grid } from "@material-ui/core"
function Footer() {
  return (
    <>
      <Box mt={2} mb={2}>
        <Grid container justify="center">
          <Grid item>
            <Typography variant="caption">
              &copy; MicroStep, spol. s r.o.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Footer
