import React, { createContext, useState } from "react"
import { Product, BranchAndPlatform } from "../types";
interface SoftwareState {
  product: Product
  branch: BranchAndPlatform
  type: number
}

interface ContextProps {
  softwareState: SoftwareState
  setSoftwareState: any
}

interface ComponentProps {
  defaultState: SoftwareState
}

const SoftwareContext = createContext<ContextProps>({} as ContextProps)

const SoftwareContextComponent: React.FunctionComponent<ComponentProps> = props => {
  const [softwareState, setSoftwareState] = useState<SoftwareState>(
    props.defaultState
  )

  return (
    <SoftwareContext.Provider
      value={{ softwareState, setSoftwareState } as ContextProps}
    >
      {props.children}
    </SoftwareContext.Provider>
  )
}

export { SoftwareContext, SoftwareContextComponent }
