import React, { useEffect, useState } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import LicenceServer from "../servers/LicenceServer";
import VersionInfosServer from "../servers/VersionInfosServer";
import { Skeleton } from "@material-ui/lab";
import { Localization } from "../localization/Localization";

class AllSoftwareScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowedProductsLoaded: false,
      allowedProducts: [],

      productsLoaded: false,
      products: [],
    };
  }

  isLoaded = () =>
    this.state.allowedProductsLoaded && this.state.productsLoaded;

  loadAllowedProducts = () => {
    LicenceServer.get(`api/Products`).then((data) => {
      this.setState({
        allowedProductsLoaded: true,
        allowedProducts: data,
      });
    });
  };

  loadProducts = async (productNames) => {
    console.log(productNames);
    VersionInfosServer.post(`api/ProductsInformation`, productNames).then(
      (data) => {
        var products = [];
        data.forEach((product) => {
          products.push({
            productName: product.product,
            displayName: product.displayname,
            description: product.description,
            logo: product.logo,
          });
        });
        this.setState({ productsLoaded: true, products });
      }
    );
  };

  componentDidMount() {
    this.loadAllowedProducts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.allowedProductsLoaded && this.state.allowedProductsLoaded) {
      this.loadProducts(this.state.allowedProducts);
    }
  }

  render() {
    return (
      <>
        <Typography variant="h4" color="primary" gutterBottom>
          <Localization>software.title</Localization>
        </Typography>
        <Grid container spacing={2}>
          {this.isLoaded() &&
            this.state.products.map((product, index) => {
              return (
                <Grid key={index} item md={3}>
                  <Card elevation={5}>
                    <CardActionArea
                      component={Link}
                      to={`/software/${product.productName}`}
                    >
                      <Box pl={3} pr={3} pt={1}>
                        <CardMedia
                          src={`data:image/svg+xml;base64,${product.logo}`}
                          component="img"
                          title={product.displayName}
                        ></CardMedia>
                      </Box>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          {product.displayName}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {product.description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              );
            })}
          {!this.isLoaded() && (
            <Grid item md={3}>
              <Card elevation={5}>
                <CardActionArea>
                  <Box pl={3} pr={3} pt={1}>
                    <Skeleton variant="rect" height={170} />
                  </Box>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      <Skeleton />
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      <Skeleton />
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}

export default AllSoftwareScreen;
