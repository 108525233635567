import React, { useEffect, useState } from "react"

import { FormControlLabel, Checkbox } from "@material-ui/core"
import UserManagementServer from "../servers/UserManagementServer"
import { Localization } from "../localization/Localization"

const NewslettersSubscription = () => {
  const [reception, setReception] = useState<boolean>(false)

  useEffect(() => {
    UserManagementServer.get(`api/User/NewslettersSubscription`).then(data =>
      setReception(data)
    )
  }, [])

  const handleReceptionChange = (event: any) => {
    let decision: boolean = event.target.checked
    setReception(decision)
    UserManagementServer.post(`api/User/NewslettersSubscription/${decision}`)
  }

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={reception}
            onChange={handleReceptionChange}
            name="enabled"
            color="primary"
          />
        }
        label={
          <Localization>profile.software_newsletters_reception</Localization>
        }
      />
    </>
  )
}
export default NewslettersSubscription
