import React from "react"

import { Box } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { Link } from "react-router-dom"
import LicenceServer from "../../../servers/LicenceServer"
import { Localization } from "../../../localization/Localization"

class NoSubscriptionWarning extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      subscribed: true
    }
  }
  componentDidMount() {
    LicenceServer.get(`api/subscriptions/subscribed`).then(data => {
      this.setState({ subscribed: data })
    })
  }

  render() {
    if (this.state.subscribed) return <></>

    return (
      <>
        <Box mb={2}>
          <Alert severity="warning">
            <AlertTitle>
              <Localization>
                subscription.cant_use_newest_version_title
              </Localization>
            </AlertTitle>
            <Localization>
              subscription.cant_use_newest_version_description
            </Localization>{" "}
            <strong>
              <Localization>
                subscription.cant_use_newest_version_consider
              </Localization>{" "}
              <Link to="/subscription">
                <Localization>
                  subscription.cant_use_newest_version_subscribing
                </Localization>
              </Link>
              .
            </strong>
          </Alert>
        </Box>
      </>
    )
  }
}

export default NoSubscriptionWarning
