import Axios from "axios"

var url = "https://versioninfos.microstep.sk/"

class VersionInfosServer {
  static async get(route, body = {}) {
    try {
      const res = await Axios.get(url + route, body)
      return res.data
    } catch (err) {
      throw err
    }
  }
  static async post(route, body = {}) {
    const res = await Axios.post(url + route, body)
    return res.data
  }
}

export default VersionInfosServer
