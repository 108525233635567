import React, { useState, useEffect, useContext } from "react"
import { Box, Divider } from "@material-ui/core"

import NoSubscriptionWarning from "./components/NoSubscriptionWarning"
import LatestVersionDownload from "./components/LatestVersionDownload"
import LatestVersionReleaseNotes from "./components/LatestVersionReleaseNotes"
import LatestVersionBetaReleaseNotes from "./components/LatestVersionBetaReleaseNotes"
import PreviousVersions from "./components/PreviousVersions"
import AllVersions from "./components/AllVersions"
import LicenceServer from "../../servers/LicenceServer"
import VersionInfosServer from "../../servers/VersionInfosServer"
import { SoftwareContext } from "../../context/SoftwareContext"
import { Alert } from "@material-ui/lab"
import { Version, ReleaseNote } from "../../types";

interface State {
  hasAnyVersion: boolean
  latestVersion: Version
  latestVersionReleaseNotes: ReleaseNote[]
  latestVersionCollectedBetaReleaseNotes: ReleaseNote[]
  isProductFree: boolean
}

const Downloads = () => {
  const [state, setState] = useState<State>({
    hasAnyVersion: true,
    isProductFree: true
  } as State)

  const { softwareState } = useContext(SoftwareContext)

  const fetchLatestVersion = async () => {
    let newState: State = state

    try {
      const latestVersion: Version = await LicenceServer.get(
        `api/products/${softwareState.product}/branch/${softwareState.branch.number}/${softwareState.branch.platform}/versions/${softwareState.type}/latest`
      )
      newState = { ...newState, latestVersion, hasAnyVersion: true }
      try {
        const latestVersionReleaseNotes: ReleaseNote[] = (
          await VersionInfosServer.get(
            `rest/${softwareState.product}/${softwareState.branch.number}/${softwareState.branch.platform}/Versions/${latestVersion.number}/${latestVersion.type}/${latestVersion.iteration}/ReleaseNotes`
          )
        )
        .filter((note: ReleaseNote) => note.String.trim() != "")  //  keby na nieco take doslo
        
        const latestVersionCollectedBetaReleaseNotes: ReleaseNote[] = (
          await VersionInfosServer.get(
            `api/BetaVersionReleaseNotes/${softwareState.product}/${softwareState.branch.number}/${softwareState.branch.platform}/${latestVersion.number}`
          )
        )
        .map((noteWithIteration: any) => noteWithIteration.ReleaseNotes).flat()
        .filter((note: string) => note.trim() !== "")  //  keby na nieco take doslo

        const isProductFree = await LicenceServer.get(
          `api/products/${softwareState.product}/free`
        )

        newState = {
          ...newState,
          latestVersionReleaseNotes,
          latestVersionCollectedBetaReleaseNotes,
          isProductFree
        }
      } catch (error) {
        newState = { ...state, latestVersionReleaseNotes: [] }
      }
    } catch (error) {
      newState = { ...state, hasAnyVersion: false }
    }

    setState(newState)
  }

  useEffect(() => {
    setState({
      hasAnyVersion: true,
      isProductFree: true
    } as State)
    fetchLatestVersion()
  }, [softwareState])

  return (
    <>
      {state.hasAnyVersion && (
        <>
          {!state.isProductFree && <NoSubscriptionWarning />}

          <LatestVersionDownload version={state.latestVersion} />

          <Box mt={2} mb={2}>
            <Divider light />
          </Box>

          <LatestVersionReleaseNotes
            releaseNotes={state.latestVersionReleaseNotes}
          />

          {softwareState.type == 3 && (
            <LatestVersionBetaReleaseNotes
              releaseNotes={state.latestVersionCollectedBetaReleaseNotes}
            />
          )}

          <Box mt={1} mb={2}>
            <Divider light />
          </Box>

          <PreviousVersions />

          <AllVersions product={softwareState.product} />
        </>
      )}

      {!state.hasAnyVersion && (
        <>
          <Alert severity="info">
            This branch with this type has no versions released
          </Alert>
        </>
      )}
    </>
  )
}
export default Downloads
