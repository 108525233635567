import React, { useEffect } from "react"

import { Box, Grid } from "@material-ui/core"
import ResetPasswordCard from "./ResetPasswordCard"

function ResetPasswordScreen(props) {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={4}>
        <ResetPasswordCard match={props.match} />
      </Grid>
    </Grid>
  )
}

export default ResetPasswordScreen
