import Axios from "axios"

var url = "https://msusermanagement.azurewebsites.net/"

class UserManagementServer {
  static async get(route, body = {}) {
    try {
      const res = await Axios.get(url + route, body)
      return res.data
    } catch (error) {
      throw error
    }
  }
  static async post(route, body = {}) {
    const res = await Axios.post(url + route, body)
    return res.data
  }
  static async patch(route, body = {}) {
    const res = await Axios.patch(url + route, body)
    return res.data
  }
}

export default UserManagementServer
