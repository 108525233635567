import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  IconButton,
  DialogContentText,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

import { LicenceForm } from "./LicenceForm";
import LicenceServer from "../../servers/LicenceServer";
import { AppContext } from "../../context/AppContext";
import { Localization } from "../../localization/Localization";
import { Licence, LicenceEdit } from "../../types";

interface EditLicenceDialogProps {
  licenceId: string;
  open: boolean;
  close: () => void;
}

const EditLicenceDialog = ({
  licenceId,
  open,
  close,
}: EditLicenceDialogProps) => {
  const [licence, setLicence] = useState<LicenceEdit>();

  const [initialLicence, setInitialLicence] = useState<LicenceEdit>();
  const [initialLicenceLoaded, setInitialLicenceLoaded] = useState(false);

  const { appDispatch } = useContext(AppContext);

  const handleSave = () => {

    if (licence?.product === null) {
      appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: "Product must be selected.",
        },
      });
      return;
    }

    if (licence?.productBranches.length === 0) {
      appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: "At least one branch must be selected.",
        },
      });
      return;
    }

    if (licence?.activationMethods.length === 0)
    {
      appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: "At least one activation method must be selected.",
        },
      });
      return;
    }

    if (licence?.haveLicenceForMachine && licence?.licenceForMachineNumber === undefined) {
      appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: "The machine number should be specified.",
        },
      });
      return;
    }

    //first check is looking if we have floating license selected as activation method. For context look: ActivationMethod in types.d.tsx
    if (licence?.activationMethods.includes(2) && licence.licenceForMachineNumber !== undefined) {
      appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: "Floating and licence for machine can't be seclected together.",
        },
      });
      return;
    }


    LicenceServer.post(`api/ManageLicences/${licenceId}`, licence as Licence).then(() => {
      appDispatch({
        type: "showMessage",
        data: {
          severity: "success",
          value: "User edited successfuly.",
        },
      });
      close();
      window.location.reload(false);
    });
  };

  useEffect(() => {
    LicenceServer.get(`api/ManageLicences/${licenceId}`).then(
      (licence: Licence) => {
        setInitialLicence(licence);
        setInitialLicenceLoaded(true);
      }
    );
  }, []);

  return (
    <>
      <Dialog open={open} onClose={close}>
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Localization>users.licence_edit</Localization>
            </Grid>
            <Grid item>
              <IconButton onClick={close} color="primary">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          {initialLicenceLoaded && (
            <LicenceForm
              onChange={(licence) => setLicence(licence)}
              initialLicence={initialLicence}
              allowDisplayName={true}
              licenceValidityEditable={false}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            startIcon={<SaveAltIcon />}
          >
            <Localization>util.save</Localization>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditLicenceDialog;
