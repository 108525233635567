import React, { useEffect } from "react";
import { Typography, Button, Box, Grid, Chip } from "@material-ui/core";
import UserLicencesTable from "./UserLicencesTable";
import Licences from "./Licences/Licences";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CreateLicenceForUserDialog from "./Licences/CreateLicenceForUserDialog";
import CreateSubscriptionForUserDialog from "./Subscriptions/CreateSubscriptionForUserDialog";
import ManageUserSubscription from "./Subscriptions/ManageUserSubscription";
import UserManagementServer from "../servers/UserManagementServer";
import ResetPasswordOfUser from "./ResetPasswordOfUser";
import ShowHistoryOfUser from "./ShowHistoryOfUser";
import DeleteUser from "./DeleteUser";
import { EditUser } from "./EditUser";
import { Authorize } from "../util/Authorize";
import { Localization } from "../localization/Localization";

import { MachineNumberChip } from "../util/CustomChip";

class UserManageScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      createLicenceForUserDialogOpen: false,
      createSubscriptionForUserDialogOpen: false,
      machines: [],
      machinesLoaded: false,
    };

    this.user = props.match.params.user;
  }

  loadMachines() {
    UserManagementServer.get(`api/ManageMachines/user/${this.user}`).then(
      (data) => {
        this.setState({ machines: data, machinesLoaded: true });
        console.log(data);
      }
    );
  }

  componentDidMount() {
    this.loadMachines();
  }

  render() {
    return (
      <>
        <Box mb={1}>
          <Grid container justify="center">
            <Grid item>
              <Typography variant="h4" color="primary">
                {this.user}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          {this.state.machinesLoaded && this.state.machines.length > 0 && (
            <Grid container justify="center">
              <Grid item>
                <Typography variant="subtitle2" color="primary">
                  <Localization>users.machines</Localization>:
                  {this.state.machines.map((machine) => (
                    <MachineNumberChip
                      key={machine}
                      label={machine}
                      color="secondary"
                      size="small"
                    />
                  ))}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>

        <Authorize
          sufficientRole={["UserAdmin"]}
          yes={
            <Box mb={2}>
              <Grid container justify="flex-end">
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => {
                      this.setState({
                        createSubscriptionForUserDialogOpen: true,
                      });
                    }}
                  >
                    <Localization>users.add_subscription</Localization>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          }
        />

        <Box mb={4}>
          <ManageUserSubscription user={this.user} />
        </Box>

        <Authorize
          sufficientRole={["UserAdmin"]}
          yes={
            <Box mb={2}>
              <Grid container justify="flex-end">
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => {
                      this.setState({ createLicenceForUserDialogOpen: true });
                    }}
                  >
                    {<Localization>users.add_licence</Localization>}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          }
        />

        <Licences user={this.user} />

        <Authorize
          sufficientRole={["LicenceAdmin"]}
          yes={
            <CreateLicenceForUserDialog
              user={this.user}
              open={this.state.createLicenceForUserDialogOpen}
              close={() => {
                this.setState({ createLicenceForUserDialogOpen: false });
              }}
            />
          }
        />

        <Authorize
          sufficientRole={["UserAdmin"]}
          yes={
            <CreateSubscriptionForUserDialog
              user={this.user}
              open={this.state.createSubscriptionForUserDialogOpen}
              close={() => {
                this.setState({ createSubscriptionForUserDialogOpen: false });
              }}
            />
          }
        />

        <Box mt={4} mb={2}>
          <Grid container spacing={2}>
            <Grid item>
              <ResetPasswordOfUser user={this.user} />
            </Grid>
            <Authorize
              sufficientRole={["UserAdmin"]}
              yes={
                <>
                  <Grid item>
                    <EditUser userName={this.user} />
                  </Grid>
                  <Grid item>
                    <DeleteUser user={this.user} />
                  </Grid>
                </>
              }
            />
            <Authorize
              sufficientRole={["LicenceAdmin"]}
              yes={
                <>
                  <Grid item>
                    <ShowHistoryOfUser userName={this.user} />
                  </Grid>
                </>
              }
            />
          </Grid>
        </Box>
      </>
    );
  }
}

export default UserManageScreen;
