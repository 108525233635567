import React from "react";
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Grid,
} from "@material-ui/core";
import VersionInfosServer from "../../servers/VersionInfosServer";
import { DateTime } from "../../util/DateTime";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import { SoftwareContext } from "../../context/SoftwareContext";

type Newsletter = {
  Title: string;
  Abstract: string;
  DownloadUrl: string;
  DateTime: string;
};

interface Props {}

interface State {
  loaded: boolean;
  newsletters: Array<Newsletter>;
}

class Newsletters extends React.Component<Props, State> {
  public static contextType = SoftwareContext;
  private product: string;

  constructor(props: Props, context: any) {
    super(props, context);
    this.product = this.context.softwareState.product;
    this.state = {
      loaded: false,
      newsletters: [],
    };
  }

  isLoaded(): boolean {
    return this.state.loaded;
  }

  componentDidMount(): void {
    VersionInfosServer.get(`api/Newsletters/${this.product}`).then(
      (data: Newsletter[]) => {
        this.setState({
          loaded: true,
          newsletters: data,
        });
      }
    );
  }

  render() {
    if (!this.isLoaded()) return <Skeleton variant="rect" height={100} />;

    return (
      <>
        <Grid container spacing={2} direction="column">
          {this.state.newsletters.map((newsletter, index) => {
            return (
              <Grid item>
                <Card elevation={5}>
                  <CardActionArea href={newsletter.DownloadUrl}>
                    <CardContent>
                      <Typography variant="h6">{newsletter.Title}</Typography>
                      <Typography variant="body1">
                        {newsletter.Abstract}
                      </Typography>

                      <Typography variant="button" color="primary">
                        <DateTime from={newsletter.DateTime} />
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  }
}

export default Newsletters;
