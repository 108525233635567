import React from "react"
import {
  FormControl,
  InputLabel,
  Box,
  Input,
  InputAdornment,
  Button,
  Backdrop
} from "@material-ui/core"
import VpnKeyRoundedIcon from "@material-ui/icons/VpnKeyRounded"
import UserManagementServer from "../servers/UserManagementServer"
import { AppContext } from "../context/AppContext"
import { withRouter } from "react-router-dom"
import { PasswordInput } from "../util/PasswordInput"

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newPassword: "",
      newPasswordConfirmation: ""
    }
  }

  componentDidMount() {}

  handleSet = () => {
    const { appDispatch } = this.context

    if (this.state.newPassword !== this.state.newPasswordConfirmation) {
      appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: `Password and password confirmation must be equal`
        }
      })
      return
    }

    UserManagementServer.post(
      `api/User/${this.props.match.params.username}/ResetPassword`,
      {
        Token: this.props.match.params.token,
        NewPassword: this.state.newPassword,
        NewPasswordConfirmation: this.state.newPasswordConfirmation
      }
    )
      .then(data => {
        this.props.history.push("/")
        appDispatch({
          type: "showMessage",
          data: {
            severity: "success",
            value: `Password was set successfuly. You can login now with your credentials.`
          }
        })
      })
      .catch(error => {
        appDispatch({
          type: "showMessage",
          data: {
            severity: "error",
            value: `Error: ${error.response.data}`
          }
        })
      })
  }

  componentDidUpdate() {}

  render() {
    return (
      <>
        <form onSubmit={e => e.preventDefault()}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="newPassword">New password</InputLabel>
            <PasswordInput
              value={this.state.newPassword}
              onChange={e => {
                this.setState({ newPassword: e.target.value })
              }}
              autoComplete="one-time-code"
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="newPasswordConfirmation">
              New password again
            </InputLabel>
            <PasswordInput
              value={this.state.newPasswordConfirmation}
              onChange={e => {
                this.setState({ newPasswordConfirmation: e.target.value })
              }}
              autoComplete="one-time-code"
            />
          </FormControl>

          <Box mt={3}>
            <Button
              onClick={this.handleSet}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Set new password
            </Button>
          </Box>
        </form>
      </>
    )
  }
}

ResetPasswordForm.contextType = AppContext

export default withRouter(ResetPasswordForm)
