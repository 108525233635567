import React from "react";

import * as Ui from "@material-ui/core";
import Theme from "./theme/MicrostepTheme";
import LoginScreen from "./login/LoginScreen";
import Portal from "./portal/Portal";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AppContextComponent, AppContext } from "./context/AppContext";
import { UserContext } from "./context/UserContext";
import Messages from "./util/Messages";
import ResetPasswordScreen from "./reset-password/ResetPasswordScreen";
import LoginImmediately from "./login/LoginImmediately";

function App() {
  return (
    <AppContextComponent>
      <Ui.ThemeProvider theme={Theme}>
        <Ui.CssBaseline />
        <Ui.Container maxWidth="lg">
          <Messages />
          <BrowserRouter>
            <Switch>
              <Route
                path="/reset-password/:username/:token(.*)"
                component={ResetPasswordScreen}
              ></Route>
              <Route path="/login/:token" component={LoginImmediately} />
              <Route>
                <UserContext.Consumer>
                  {(value) =>
                    value.userState.loggedIn ? <Portal /> : <LoginScreen />
                  }
                </UserContext.Consumer>
              </Route>
            </Switch>
          </BrowserRouter>
        </Ui.Container>
      </Ui.ThemeProvider>
    </AppContextComponent>
  );
}

export default App;
