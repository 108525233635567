import React, { useEffect, useState } from "react"
import { Button, Grid, Box } from "@material-ui/core"
import { Localization } from "../../../localization/Localization"
import VersionInfosServer from "../../../servers/VersionInfosServer"
import { Product } from "../../../types";

interface Props {
  product: Product
}

const AllVersions = ({ product }: Props) => {
  let [url, setUrl] = useState<string>()

  const fetchUrl = async () => {
    const repositoryUrl: string = await VersionInfosServer.get(
      `rest/${product}/AllVersionsRepositoryUrl`
    )
    setUrl(repositoryUrl)
  }

  useEffect(() => {
    fetchUrl()
  }, [])

  return (
    <Box mt={1}>
      <Grid container justify="flex-end">
        <Grid item>
          {url && (
            <Button href={url} target="_blank" size="small">
              <Localization>downloads.view_all_versions</Localization>
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default AllVersions
