import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Typography,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import { times } from "lodash";
import { Localization } from "../localization/Localization";
import { MachineNumberChip } from "../util/CustomChip";

class UsersTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <TableContainer component={Paper} elevation={5}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Localization>users.username</Localization>
              </TableCell>
              <TableCell>
                <Localization>users.fullname</Localization>
              </TableCell>
              <TableCell>
                <Localization>users.machines</Localization>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!this.props.loaded && (
              <>
                {times(5, () => (
                  <TableRow>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
            {this.props.loaded &&
              this.props.users.map((user) => (
                <TableRow key={user.userName}>
                  <TableCell>{user.userName}</TableCell>
                  <TableCell>{user.fullName}</TableCell>
                  <TableCell>
                    <Typography>
                      {user.machines.map((machine) => (
                        <MachineNumberChip
                          key={machine}
                          label={machine}
                          size="small"
                          color="primary"
                        />
                      ))}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      color="secondary"
                      variant="contained"
                      component={Link}
                      to={`users/${user.userName}`}
                    >
                      {<Localization>users.manage</Localization>}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default UsersTable;
