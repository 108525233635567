import jwt_decode from "jwt-decode"
import User from "./User"

class UserLoggedIn {
  constructor(token) {
    this.token = token

    var tokenData = jwt_decode(token)
    console.log(tokenData)
    this.user = new User(
      tokenData.sub,
      tokenData.name,
      tokenData.full_name,
      tokenData.email,
      tokenData.role,
      tokenData.preferred_language
    )
    this.validUntil = tokenData.exp
  }

  token() {
    return this.token
  }

  user() {
    return this.user
  }

  validUntil() {
    return this.validUntil
  }

  isExpired() {
    return new Date().getTime() >= this.validUntil()
  }
}

export default UserLoggedIn
