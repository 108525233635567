import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  IconButton,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import moment from "moment";

import CloseIcon from "@material-ui/icons/Close";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import LicenceServer from "../../servers/LicenceServer";
import { AppContext } from "../../context/AppContext";
import UserManagementServer from "../../servers/UserManagementServer";
import { Localization } from "../../localization/Localization";

class CreateSubscriptionForUserDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validFrom: moment(),
      validUntil: moment().add(1, "y"),
      hasNote: false,
      note: null,
    };
  }

  componentDidMount() {}

  handleNoteCheckBox = (event) => {
    var checked = event.target.checked;

    this.setState({
      hasNote: checked,
      note: null,
    });
  };

  handleClose = () => {
    this.props.close();
  };

  handleSubmit = async () => {
    if (this.state.validUntil.isSameOrBefore(this.state.validFrom)) {
      this.context.appDispatch({
        type: "showMessage",
        data: {
          severity: "warning",
          value: "Subscription expiration must be greater.",
        },
      });
      return;
    }

    var body = {
      validFrom: this.state.validFrom.toISOString(),
      validUntil: this.state.validUntil.toISOString(),
      note: this.state.note,
    };

    LicenceServer.post(
      `api/ManageSubscriptions/create/${this.props.user}`,
      body
    ).then((data) => {
      this.context.appDispatch({
        type: "showMessage",
        data: {
          severity: "success",
          value: "Subscription added successfuly.",
        },
      });
      this.props.close();
      window.location.reload(false);
    });
  };

  render() {
    return (
      <>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Localization>users.add_subscription</Localization>
              </Grid>
              <Grid item>
                <IconButton onClick={this.handleClose} color="primary">
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid container justify="space-around">
                <Grid item>
                  <Typography variant="h5" color="primary" align="center">
                    <b>
                      <Localization>users.subscription_from</Localization>:
                    </b>
                  </Typography>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="DD.MM.yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date picker inline"
                    value={this.state.validFrom}
                    onChange={(date) => this.setState({ validFrom: date })}
                    fullWidth
                    variant="static"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h5" color="primary" align="center">
                    <b>
                      <Localization>users.subscription_until</Localization>:
                    </b>
                  </Typography>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="DD.MM.yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date picker inline"
                    value={this.state.validUntil}
                    onChange={(date) => this.setState({ validUntil: date })}
                    fullWidth
                    variant="static"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>

            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={"note"}
                        defaultChecked={this.state.hasNote}
                        onChange={this.handleNoteCheckBox}
                      />
                    }
                    label={<Localization>users.subscription_note</Localization>}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={5}>
                {this.state.hasNote && (
                  <TextField
                    id="outlined-basic"
                    label={<Localization>users.subscription_note</Localization>}
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    fullWidth
                    value={this.state.note}
                    onChange={(e) => {
                      const { value } = e.target;

                      this.setState({ note: value });
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleSubmit}
              color="primary"
              variant="contained"
              startIcon={<AddCircleOutlineIcon />}
            >
              <Localization>util.add</Localization>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

CreateSubscriptionForUserDialog.contextType = AppContext;

export default CreateSubscriptionForUserDialog;
